import * as React from "react";
import Epilepsysensor from "../../../Elements/Icon/Icons/Epilepsysensor";
import Movement from "../../../Elements/Icon/Icons/Movement";
import Notinbed from "../../../Elements/Icon/Icons/Notinbed";
import Fridge from "../../../Elements/Icon/Icons/Fridge";
import Notstoodup from "../../../Elements/Icon/Icons/Notstoodup";
import SelfcheckFail from "../../../Elements/Icon/Icons/Safemate/solid/SelfcheckFail";
import OutdatedPosition from "../../../Elements/Icon/Icons/Safemate/solid/OutdatedPosition";
import PowerOff from "../../../Elements/Icon/Icons/Safemate/solid/PowerOff";
import BatteryQuarter from "../../../Elements/Icon/Icons/Safemate/solid/BatteryQuarter";
import UserIdle from "../../../Elements/Icon/Icons/Safemate/solid/UserIdle";
import CheckCircle from "../../../Elements/Icon/Icons/Safemate/solid/CheckCircle";
import HeadlineAlarm from "@Elements/Icon/Icons/EventSetup/HeadlineAlarm";
import StatusQuestion from "../../../Elements/Icon/Icons/Status/StatusQuestion";
import StatusGeofenceWarning from "../../../Elements/Icon/Icons/Status/StatusGeofenceWarning";
import StatusTcpConnectionDisabled from "../../../Elements/Icon/Icons/Status/StatusTcpConnectionDisabled";
import { FallSensor } from "@Elements/Icon/Icons/FontAwesome";
import Fire from "@Elements/Icon/Icons/FontAwesome/Fire";
import BatteryEmpty from "@Elements/Icon/Icons/Safemate/solid/BatteryEmpty";

export enum StatusColor{
  UNKNOWN = "unknown",
  FIRE_WARNING = "fire",
  MAN_DOWN_WARNING = "alarm",
  EPILEPSY_WARNING = "epilepsy",
  ALARM_WARNING = "alarm",
  WANDERER_WARNING = "alarm",
  OUT_OF_BED_WARNING = "out-of-bed",
  FRIDGE_WARNING = "fridge",
  IN_BED_WARNING = "in-bed",
  SELF_CHECK_WARNING = "selfcheck",
  OUTDATED_POSITION_WARNING = "selfcheck",
  GEOFENCE_WARNING = "geofenceWarning",
  POWER_FAILURE = "powerFailure",
  BATTERY_WARNING = "lowBattery",
  INACTIVE_WARNING = "lowBattery",
  OUT_OF_BATTERY_WARNING = "lowBattery",
  OFFLINE_WARNING = "offline",
  TCP_STATUS_DISABLED_WARNING = "selfcheck",
  DEFAULT = "clear",

  DEFAULT_STATUS = "defaultStatus",
  DEFAULT_ALARM = "defaultAlarm"
}

export enum StatusText{
  LAST_MESSAGE_IN = "",
  FIRE_WARNING = "fireStatusHover",
  MAN_DOWN_WARNING = "manDownStatusHover",
  EPILEPSY_WARNING = "epilepsyStatusHover",
  ALARM_WARNING = "alarmStatusHover",
  WANDERER_WARNING = "wandererStatusHover",
  OUT_OF_BED_WARNING = "outOfBedStatusHover",
  FRIDGE_WARNING = "fridgeStatusHover",
  IN_BED_WARNING = "inBedStatusHover",
  SELF_CHECK_WARNING = "selfCheckStatusHover",
  OUT_OF_BATTERY_WARNING = "outOfBatteryStatusHover",
  OUTDATED_POSITION_WARNING = "outdatedPositionHover",
  GEOFENCE_WARNING = "geofenceStatusHover",
  POWER_FAILURE = "powerFailureStatusHover",
  BATTERY_WARNING = "batteryStatusHover",
  INACTIVE_WARNING = "staticStatusHover",
  OFFLINE_WARNING = "offlineStatusHover",
  TCP_STATUS_DISABLED_WARNING = "tcpStatusDisabledHover",
  DEFAULT = "clearStatusHover"
}

export enum StatusType{
  LAST_MESSAGE_IN = "lastMsgIn",
  FIRE_WARNING = "fireWarning",
  MAN_DOWN_WARNING = "manDownWarning",
  EPILEPSY_WARNING = "epilepsyWarning",
  ALARM_WARNING = "alarmWarning",
  WANDERER_WARNING = "wandererWarning",
  OUT_OF_BED_WARNING = "outOfBedWarning",
  FRIDGE_WARNING = "fridgeWarning",
  IN_BED_WARNING = "inBedWarning",
  SELF_CHECK_WARNING = "selfCheckWarning",
  OUT_OF_BATTERY_WARNING = "outOfBatteryWarning",
  OUTDATED_POSITION_WARNING = "outdatedPositionWarning",
  GEOFENCE_WARNING = "geofenceWarning",
  POWER_FAILURE = "powerFailure",
  BATTERY_WARNING = "batteryWarning",
  INACTIVE_WARNING = "inactiveWarning",
  OFFLINE_WARNING = "offlineWarning",
  UNKNOWN_WARNING = "unknownWarning",
  TCP_STATUS_DISABLED_WARNING = "tcpStatusDisabledWarning",
  CHARGING = "charging",
  DEFAULT = "default"
}

export interface DeviceStatus{
  icon: React.ReactElement;
  color: StatusColor;
  text: StatusText;
}

export interface StatusPriority{
  type: StatusType;
  status: DeviceStatus;
  check?: any;
  priority: number;
  extraCheck?: Array<{ type: StatusType; check: any, priority: number}>;
}

interface StatusIconDefinition{
  [index: string]: DeviceStatus;
}

export const statusIconDefinition: StatusIconDefinition = {
  LAST_MESSAGE_IN: {
    icon: <StatusQuestion/>,
    color: StatusColor.DEFAULT_STATUS,
    text: StatusText.LAST_MESSAGE_IN,
  },
  FIRE_WARNING: {
    icon: <Fire/>,
    color: StatusColor.DEFAULT_ALARM,
    text: StatusText.FIRE_WARNING
  },
  MAN_DOWN_WARNING: {
    icon: <FallSensor large/>,
    color: StatusColor.DEFAULT_STATUS,
    text: StatusText.MAN_DOWN_WARNING
  },
  EPILEPSY_WARNING: {
    icon: <Epilepsysensor/>,
    color: StatusColor.DEFAULT_ALARM,
    text: StatusText.EPILEPSY_WARNING
  },
  ALARM_WARNING: {
    icon: <HeadlineAlarm/>,
    color: StatusColor.DEFAULT_ALARM,
    text: StatusText.ALARM_WARNING
  },
  WANDERER_WARNING: {
    icon: <Movement/>,
    color: StatusColor.DEFAULT_STATUS,
    text: StatusText.WANDERER_WARNING
  },
  OUT_OF_BED_WARNING: {
    icon: <Notinbed/>,
    color: StatusColor.DEFAULT_STATUS,
    text: StatusText.OUT_OF_BED_WARNING
  },
  FRIDGE_WARNING: {
    icon: <Fridge/>,
    color: StatusColor.DEFAULT_STATUS,
    text: StatusText.FRIDGE_WARNING
  },
  IN_BED_WARNING: {
    icon: <Notstoodup/>,
    color: StatusColor.DEFAULT_STATUS,
    text: StatusText.IN_BED_WARNING
  },
  OUT_OF_BATTERY_WARNING: {
    icon: <BatteryEmpty/>,
    color: StatusColor.DEFAULT_STATUS,
    text: StatusText.OUT_OF_BATTERY_WARNING
  },
  SELF_CHECK_WARNING: {
    icon: <SelfcheckFail/>,
    color: StatusColor.DEFAULT_STATUS,
    text: StatusText.SELF_CHECK_WARNING
  },
  OUTDATED_POSITION_WARNING: {
    icon: <OutdatedPosition/>,
    color: StatusColor.DEFAULT_STATUS,
    text: StatusText.OUTDATED_POSITION_WARNING
  },
  GEOFENCE_WARNING: {
    icon: <StatusGeofenceWarning/>,
    color: StatusColor.DEFAULT_STATUS,
    text: StatusText.GEOFENCE_WARNING
  },
  POWER_FAILURE: {
    icon: <PowerOff/>,
    color: StatusColor.DEFAULT_STATUS,
    text: StatusText.POWER_FAILURE
  },
  BATTERY_WARNING: {
    icon: <BatteryQuarter/>,
    color: StatusColor.DEFAULT_STATUS,
    text: StatusText.BATTERY_WARNING
  },
  INACTIVE_WARNING: {
    icon: <UserIdle/>,
    color: StatusColor.DEFAULT_STATUS,
    text: StatusText.INACTIVE_WARNING
  },
  OFFLINE_WARNING: {
    icon: <PowerOff/>,
    color: StatusColor.DEFAULT_STATUS,
    text: StatusText.OFFLINE_WARNING
  },
  TCP_STATUS_DISABLED_WARNING: {
    icon: <StatusTcpConnectionDisabled/>,
    color: StatusColor.DEFAULT_STATUS,
    text: StatusText.TCP_STATUS_DISABLED_WARNING
  },
  DEFAULT: {
    icon: <CheckCircle/>,
    color: StatusColor.DEFAULT,
    text: StatusText.DEFAULT
  }
}

export const statusPriority: Array<StatusPriority> = [
  { type: StatusType.LAST_MESSAGE_IN, check: null, priority: 1, 
    status: statusIconDefinition.LAST_MESSAGE_IN
  },
  { type: StatusType.FIRE_WARNING, check: true, priority: 2, 
    status: statusIconDefinition.FIRE_WARNING
  },
  { type: StatusType.MAN_DOWN_WARNING, check: true, priority: 3, 
    status: statusIconDefinition.MAN_DOWN_WARNING
  },
  { type: StatusType.EPILEPSY_WARNING, check: true, priority: 4, 
    status: statusIconDefinition.EPILEPSY_WARNING
  },
  { type: StatusType.ALARM_WARNING, check: true, priority: 5, 
    status: statusIconDefinition.ALARM_WARNING
  },
  { type: StatusType.WANDERER_WARNING, check: true, priority: 6, 
    status: statusIconDefinition.WANDERER_WARNING
  },
  { type: StatusType.GEOFENCE_WARNING, check: true, priority: 7,
    status: statusIconDefinition.GEOFENCE_WARNING
  },
  { type: StatusType.OUT_OF_BED_WARNING, check: true, priority: 8, 
    status: statusIconDefinition.OUT_OF_BED_WARNING
  },
  { type: StatusType.FRIDGE_WARNING, check: true, priority: 9, 
    status: statusIconDefinition.FRIDGE_WARNING
  },
  { type: StatusType.IN_BED_WARNING, check: true, priority: 10, 
    status: statusIconDefinition.IN_BED_WARNING
  },
  { type: StatusType.OUT_OF_BATTERY_WARNING, check: true, priority: 11, 
    status: statusIconDefinition.OUT_OF_BATTERY_WARNING
  },
  { type: StatusType.SELF_CHECK_WARNING, check: true, priority: 12, 
    status: statusIconDefinition.SELF_CHECK_WARNING
  },
  { type: StatusType.OFFLINE_WARNING, check: true, priority: 13,
    status: statusIconDefinition.OFFLINE_WARNING
  },
  { type: StatusType.TCP_STATUS_DISABLED_WARNING, check: true, priority: 14,
    status: statusIconDefinition.TCP_STATUS_DISABLED_WARNING
  },
  { type: StatusType.OUTDATED_POSITION_WARNING, check: true, priority: 15,
    status: statusIconDefinition.OUTDATED_POSITION_WARNING
  },
  { type: StatusType.BATTERY_WARNING, check: true, 
    extraCheck: [{type: StatusType.CHARGING, check: false, priority: 1}], priority: 16,
    status: statusIconDefinition.BATTERY_WARNING
  },
  { type: StatusType.POWER_FAILURE, check: true, priority: 17,
    status: statusIconDefinition.POWER_FAILURE
  },
  { type: StatusType.INACTIVE_WARNING, check: true, priority: 18,
    status: statusIconDefinition.INACTIVE_WARNING
  },
  { type: StatusType.DEFAULT, priority: 100, 
    status: statusIconDefinition.DEFAULT
  }
]
