import * as React from "react";
import { injectIntl, WrappedComponentProps, useIntl } from "react-intl";
import { Field } from "formik";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import {Action, ActionFunctionAny} from "redux-actions";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import * as Api from '../Store/api';
import withFormWrapper from "@Elements/Form/formWrapper";
import Dropdown from "../../../Elements/Dropdown/Dropdown";
import { Option } from "../../../Elements/Dropdown/types";
import { CustomerForm, PostalCityResult } from "./types";
import InformationIcon from "../../../Elements/Icon/Icons/InformationIcon";
import {PaymentBillingState, AlarmState, AlarmForm, PostCity} from "../Store/types";
import { alterTree, getPostCity} from "../Store/routines";
import { AllPermissions, PolicyContext } from "../../Policy/Provider";
import { PolicyNameEnum } from "../../PolicyNameEnum";
import { FullWidthInput } from "./styled";
import {confirmModal} from "../../Settings/General/Tabs/deleteData";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";
import { MarketEnum } from "@Safemate/MarketEnum";
import {getZipCodeLength } from "@Safemate/utils";
import { checkPermissionsForCustomer } from "@Safemate/Policy/rightsUtil";
import { ICustomerWithPolicy, IFullCustomerWithPolicy } from "@Safemate/Model/Customer";




const Label = styled.label`
  text-transform: capitalize;
  display: block;
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const StyledAddressRow = styled(Row)`
  margin-bottom: 10px;
  @media (min-width: 992px) {
    display:flex;
    align-items:center; 
  }
  @media (max-width: 991px) {
    .col-md-8{
      padding-left: 0px;
    }
    .shippingDiv{
      float: left;
    }
  }

  .col-md-8{
    padding-right: 0px;
  }


  .col-md-6 .col-md-4{
    padding-left: 0px;
  }
  .col-md-6 .col-md-4 label{
    margin-top:10px;
  }
  .col-md-6 .col-md-9{
    padding-left: 0px;
  }
  .col-md-6{
    display:block;
  }
  label h4{
    padding-right:5px;
    margin-top:20px;
    font-size:16px;
  }
  .col-md-4{
    padding-left: 10px;
    padding-right: 0px;
  }
  span{
    height: 14px;
    width: 14px;
    margin-left:5px;
  }
  #useShippingAddress-label{
    padding-top:13px;
    width: max-content;
  }
  .check{
    padding-left:5px;
  }

`;

const StyledMainRow = styled(Row)`
  margin-bottom: 10px;
  @media (min-width: 992px) {
    display:flex;
    align-items:center; 
  }
  .col-md-6 .col-md-4{
    padding-left: 0px;
  }
  .col-md-6 .col-md-4 label{
    margin-top:10px;
  }
  .col-md-6 .col-md-9{
    padding-left: 0px;
  }
  .col-md-6{
    display:block;
  }
  label h4{
    padding-right:5px;
    margin-top:20px;
  }
  .col-md-4{
    padding-left: 20px;
  }

`;


interface CustomerField extends WrappedComponentProps{
  custId: number;
  preventPartnerReferenceChange?: boolean;
}

export const Email = injectIntl(({ intl: { formatMessage }, custId}: CustomerField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "deliveryAddress", defaultMessage: "Leveringsadresse"})}</Label>
      </Col>
      <Col md={8}>
      <Field
        type="text"
        name="customer.email"
        placeholder={formatMessage({id: "sub-unit-email", defaultMessage: "E-post"})}
        disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId) && custId !== 0}
        />
      </Col>
    </StyledMainRow>
  )
})

interface SubOrgNameProps{
  customerId: number;
  alterTree: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToSubOrgNameProps = ({ subUnits: { customer: { selectedCustomer: { customerId } }} }: AppState) => {
  return {
    customerId
  }
}

const mapDispatchToSubOrgNameProps = {
  alterTree
}

export const SubOrgName = connect(mapStateToSubOrgNameProps, mapDispatchToSubOrgNameProps)(withFormWrapper<CustomerForm, SubOrgNameProps>(({ formik: { setFieldValue, values: { customer }}, customerId, alterTree}) => {
  
  const { formatMessage } = useIntl();

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "subUnitName", defaultMessage: "Virksomhetsnavn *"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
          onBlur={(e: React.FocusEvent<any>) => {
            alterTree({
              ...customer,
              lastName: e.target.value
            })
          }}
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, customerId) && customerId !== 0}
          type="text"
          name="customer.lastName"
          placeholder={formatMessage({id: "subUnitName", defaultMessage: "Virksomhetsnavn"})}
        />
      </Col>
    </StyledMainRow>
  )
}))

export const OrgNummer = injectIntl(({ intl: { formatMessage }, custId}: CustomerField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "orgnum", defaultMessage: "orgNummer"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
          type="text"
          name="customer.orgNum"
          placeholder={formatMessage({id: "orgnum", defaultMessage: "org.nummer"})}
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId) && custId !== 0}
        />
      </Col>
    </StyledMainRow>
  )
})

const mapStateToPropsReference = ({ appData: {preventPartnerReferenceChange} }: AppState) => {
  return{
    preventPartnerReferenceChange
  }
}

export const Reference = connect(mapStateToPropsReference)(injectIntl(({ intl: { formatMessage }, custId, preventPartnerReferenceChange }: CustomerField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledAddressRow>
      <Col md={4}>
        <Label>{formatMessage({id: "ADDR_REFERENCE", defaultMessage: "Referanse"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
          type="text"
          name="customer.addrReference"
          placeholder={formatMessage({id: "ADDR_REFERENCE", defaultMessage: "referanse"})}
          disabled={(!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId) && custId !== 0) || preventPartnerReferenceChange}
        />
      </Col>
    </StyledAddressRow>
  )
}))

export const AddressOne = injectIntl(({ intl: { formatMessage }, custId}: CustomerField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledAddressRow>
      <Col md={4}>
        <Label>{formatMessage({id: "ADDRESS_LINE_1", defaultMessage: "Adresselinje 1"})}</Label>
        </Col>
        <Col md={8}>
          <StyledField
            type="text"
            name="customer.addressLine1"
            placeholder={formatMessage({id: "ADDRESS_LINE_1", defaultMessage: "addresslinje1"})}
            disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId) && custId !== 0}
          />
      </Col>
    </StyledAddressRow>
  )
})

export const AddressTwo = injectIntl(({ intl: { formatMessage }, custId}: CustomerField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledAddressRow>
      <Col md={4}>
      <Label>{formatMessage({id: "ADDRESS_LINE_2", defaultMessage: "Adresselinje 2"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
          type="text"
          name="customer.addressLine2"
          placeholder={formatMessage({id: "ADDRESS_LINE_2", defaultMessage: "addresslinje2"})}
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId) && custId !== 0}
        />
      </Col>
    </StyledAddressRow>
  )
})

export const Post = injectIntl(({ intl: { formatMessage }, custId}: CustomerField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledAddressRow>
      <Col md={4}>
        <Label>{formatMessage({id: "ADDR_CITY", defaultMessage: "Poststed *"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
          type="text"
          name="customer.addrCity"
          placeholder={formatMessage({id: "ADDR_CITY", defaultMessage: "poststed"})}
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId) && custId !== 0}
        />
      </Col>
    </StyledAddressRow>
  )
})

export const AddressName = injectIntl(({ intl: { formatMessage }, custId}: CustomerField) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return (
    <StyledAddressRow>
      <Col md={4}>
        <Label>{formatMessage({id: "NAME", defaultMessage: "Navn"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
          type="text"
          name="customer.addrName"
          placeholder={formatMessage({id: "NAME", defaultMessage: "navn"})}
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId) && custId !== 0}
        />
      </Col>
    </StyledAddressRow>
  )
})

interface AlarmProps extends WrappedComponentProps{
   alarmState: AlarmState;
   whiteListPhoneNumbers: string;
}

const mapAlarmCentralStateToProps = ({ subUnits: {alarmState, customer: { selectedCustomer: { firm: { whiteListPhoneNumbers } } }}}: AppState) => {
  return{ 
    alarmState,
    whiteListPhoneNumbers
  }
}

export const AlarmCentral = connect(mapAlarmCentralStateToProps)(injectIntl(
  withFormWrapper<CustomerForm, AlarmProps>(({ intl: { formatMessage }, formik: { setFieldValue, values: { customer: {custId, defaultAhp}, tempAhp } }, alarmState, whiteListPhoneNumbers}) => {

    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
    const { Comp, func } = confirmModal();
    const alarmMethods = React.useMemo(() => {
      const availableAlarmHelpProviders = alarmState.alarms.map((element:AlarmForm) => {
          return {value: `${element.ahpId}`, text: formatMessage({id: element.name, defaultMessage: element.name})}
      })
      availableAlarmHelpProviders.unshift({value: "0", text: ' -- ' + formatMessage({id: "none", defaultMessage: "ingen"}) + ' -- '})
      return availableAlarmHelpProviders;
    }, [alarmState])

    // No reason to show this dropdown when there are no options
    if (alarmMethods.length <= 1) {
      return null;
    }

    return (
      <StyledMainRow>
        <Col md={4}>
          <Label>{formatMessage({id: "subUnitsAlarmCentral", defaultMessage: "Alarmsentral"})}</Label>
        </Col>
        <Col md={8}>
          <Dropdown
            options={alarmMethods}
            onChange={(option) => {
              const { value } = option as Option;
              if( whiteListPhoneNumbers && value !== "0" ){
                setFieldValue("tempAhp", value);
                func(true);
              } else {
                setFieldValue("customer.defaultAhp", value);
              }
            }}
            disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId) && custId !== 0}
            initial={`${defaultAhp}`}
            size={{width: "100%"}}
          />  
        </Col>
        <Comp
          title={`${formatMessage({id: "whiteListPhoneNumberActivated", defaultMessage: "White list phone number is provided"})}`}
          body={
            <p>
              <span>{formatMessage({id: "whiteListPhoneNumberActivatedDesc", defaultMessage: "AHP numbers will take precedence and white list will be overwritten by AHP numbers"})}</span>
            </p>
          }
          confirmText={formatMessage({id: "ok", defaultMessage: "OK"})}
          confirmFunc={() => {
            setFieldValue("customer.defaultAhp", tempAhp);
            func(false);
          }}
          cancelHide={true}
        />
      </StyledMainRow>
    )
  })
))

interface BillingProps{
  selectedCustomer: IFullCustomerWithPolicy;
  individualPaymentProfiles: PaymentBillingState[];
  customers: IFullCustomerWithPolicy[];
}

const mapBillingStateToProps = ({ subUnits: { customer: { customers, selectedCustomer }, paymentProfile: { individualPaymentProfiles } }}: AppState) => {
  return {
    selectedCustomer,
    individualPaymentProfiles,
    customers
  }
}

export const Billing = connect(mapBillingStateToProps)(
  withFormWrapper<CustomerForm, BillingProps>(({ formik: { values: {pptyId, customer: { parent, custId }}, setFieldValue }, selectedCustomer, customers, individualPaymentProfiles}) => {

    
    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
    const { formatMessage } = useIntl();

    const [parentCustomer, billingMethod] = React.useMemo(() => {
      
      let customerName = "";

      const parentCustomer = customers.find(cust => cust.customerId === parent);

      if(parentCustomer){
        customerName = parentCustomer.firm.lastName;
      }

      return [parentCustomer, individualPaymentProfiles.map((element:PaymentBillingState) => {
        const value: number = element.pptyId;
        if(element.pptyId == 3){
          return {value, text: formatMessage({id: element.name, defaultMessage: element.name}) + ": " + customerName};
        }else{
          return {value, text: formatMessage({id: element.name, defaultMessage: element.name})};
        }
      })]
    }, [individualPaymentProfiles, customers, parent]) 

    if(!custId && !parent) return null;

    if (billingMethod.length === 1) {
      // No reason to show a choice if there is only one
      return null;
    }

    return (
      <StyledMainRow>
        <Col md={4}>
          <Label>{formatMessage({id: "subUnitsPayment", defaultMessage: "Betalingsform"})}</Label>
        </Col>
        <Col md={8}>
        {billingMethod.length === 0 
          ? <span>{formatMessage({id: "payment", defaultMessage: "Betaling"})}: {parentCustomer ? parentCustomer.firm.lastName : selectedCustomer.paymentProfile.paymentDetail}</span>
          : <Dropdown
              options={billingMethod}
              onChange={(option) => {
                const { value } = option as Option;
                setFieldValue("pptyId", value);
              }}
              disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId) && custId !== 0}
              initial={pptyId}
              size={{width: "100%"}}
            />}
        </Col>
      </StyledMainRow>
    )
  })
);


export const ShippingAddress = injectIntl(({ intl: {formatMessage}, custId}: CustomerField)=> {
  return (
      <Col md={12} >
        <StyledAddressRow>
          <Col className="shippingDiv">
            <Tooltip
              popoverId="reset-popover"
              labelId={"shippingAddressMsg"}
              defaultMessage="Shipping address is used in conjunction with shipping"
            >
              <Label><h4>{formatMessage({id: "deliveryAddress", defaultMessage: "Leveringsadresse"})}</h4></Label>
              <InformationIcon size="medium" />
            </Tooltip>
          </Col>
        </StyledAddressRow>
        <AddressName custId={custId}/>
        <Reference custId={custId}/>
        <AddressOne custId={custId}/>
        <AddressTwo custId={custId}/>
        <Zip custId={custId}/>
        <Post custId={custId}/>
      </Col>
  )
})


interface ZipProps{
  getPostCity: Routine<ActionFunctionAny<Action<any>>>;
  postCity: PostCity;
  market: MarketEnum;
  custId: number;
}
const mapStateToPropsZip = ( { appSettings: {market}, subUnits: {customer: {postCity, billingPostCity}}} : AppState) => {
  return{
    postCity,
    billingPostCity,
    market
  }
};
const mapDispatchFromStateZip = {
  getPostCity,
}

export const Zip = connect(mapStateToPropsZip, mapDispatchFromStateZip)(
  withFormWrapper<CustomerForm, ZipProps>(
    ({formik: { setFieldValue }, custId, market }) => {

    const { formatMessage } = useIntl();
    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  
    const getPostalCity = async (e: React.FormEvent<HTMLInputElement>) => {
      if(e.currentTarget.value.length == getZipCodeLength(market) ){

        const postalCity: PostalCityResult = await Api.getPostCity(e.currentTarget.value);
        if(postalCity.valid){
          setFieldValue("customer.addrCity", postalCity.result);
        }
      }
    }

    return(
      <StyledAddressRow>
      <Col md={4}>
        <Label>{formatMessage({id: "ADDR_ZIP_CODE", defaultMessage: "Postnummer"})}</Label>
      </Col>      
      <Col md={8}>
        <StyledField
          type="text"
          name="customer.addrZipCode"
          onKeyUp={getPostalCity}
          placeholder={formatMessage({id: "ADDR_ZIP_CODE", defaultMessage: "postnummer"})}
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId) && custId !== 0}
        />
      </Col>
    </StyledAddressRow>
    )
  })
)

