import {call, put, takeLatest, select} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { feedbackFailure, feedbackFailureString, feedbackSuccess } from '../../../Feedback/reducer';
import { AlarmSettingsForm, BatteryForm, PositioningForm, GeneralSettingsForm, ManDownForm, HeartRateBloodPressureForm, CommunicationForm } from '../../General/types';
import { Feedback } from '../../labels';
import { getRoles } from '../selectors';
import { SimulateForm } from '@Safemate/Settings/General/Tabs/simulate';
import { DevicePedometer } from '@Safemate/Model/DevicePedometer';

function* fetchGeneral(action: AppAction<number>){
  try {
    const general = yield call(Api.getGeneral, action.payload);
    yield put(Routines.fetchGeneral.success(general));
  }
  catch(e){
    yield put(Routines.fetchGeneral.failure());
  }
}

export type SwitchSafemate = {
  deviceId: number;
  serialNum: string;
}

function* switchSafemate(action: AppAction<SwitchSafemate>){
  try {
    yield call(Api.switchSafemate, action.payload);
    yield put(Routines.switchSafemate.success());
    yield feedbackSuccess(Feedback.DEVICE_SWAPPED);
    yield reroute();
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.switchSafemate.failure());
  }
}

function* resetSafemate(action: AppAction<number>){
  try {
    yield call(Api.resetSafemate, action.payload);
    yield put(Routines.fetchGeneral.trigger(action.payload));
    yield put(Routines.resetSafemate.success());
    yield feedbackSuccess(Feedback.DEVICE_RESET);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.resetSafemate.failure());
  }
}

function* forgetSafemate(action: AppAction<number>){
  try {
    yield call(Api.forgetSafemate, action.payload);
    yield put(Routines.forgetSafemate.success());
    yield feedbackSuccess(Feedback.DEVICE_FORGOT);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.forgetSafemate.failure());
  }
}

function* clearWifiCache(action: AppAction<number>){
  try {
    yield call(Api.clearWifiCache, action.payload);
    yield put(Routines.clearWifiCache.success());
    yield feedbackSuccess(Feedback.CLEAR_WIFI_CACHE);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.clearWifiCache.failure());
  }
}

export type DeleteSafemate = {
  deviceId: number;
  feedback: string;
  deletesAllowed: boolean;
}

function* deleteSafemate(action: AppAction<DeleteSafemate>){
  try {

    const { deviceId, feedback, deletesAllowed } = action.payload;

    if(deletesAllowed){
      yield call(Api.deleteSafemate, deviceId);
      yield put(Routines.deleteSafemate.success());
      yield feedbackSuccess(feedback);
      yield reroute();
    }else{
      yield feedbackFailureString("partnerDeniedDelete");
      yield put(Routines.deleteSafemate.failure());
    }
    
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.deleteSafemate.failure());
  }
}

export type SaveAlarmSettings = {
  deviceId: number;
  settings: AlarmSettingsForm;
}

function* saveAlarmSettings(action: AppAction<SaveAlarmSettings>){
  try{
    const savedSettings = yield call(Api.saveAlarmSettings, action.payload);
    yield put(Routines.saveAlarmSettings.success(savedSettings));
    yield feedbackSuccess(Feedback.SAVED_ALARM_SETTINGS);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveAlarmSettings.failure());
  }
}

export type SaveManDownSettings = {
  deviceId: number;
  settings: ManDownForm;
}

function* saveManDownSettings(action: AppAction<SaveManDownSettings>){
  try{
    if(action.payload.settings.fallDownSensitivity == 0){
      action.payload.settings.fallDownSensitivity = 1;
      action.payload.settings.fallDownAlert = false;
    }
    const savedSettings = yield call(Api.saveManDownSettings, action.payload);
    yield put(Routines.saveManDownSettings.success(savedSettings));
    yield feedbackSuccess(Feedback.SAVED_MAN_DOWN_SETTINGS);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveManDownSettings.failure());
  }
}

export type HeartRateBloodPresureSettings = {
  deviceId: number;
  settings: HeartRateBloodPressureForm;
}

function* saveHeartRateBloodPressureSettings(action: AppAction<HeartRateBloodPresureSettings>){
  try{
    const savedSettings = yield call(Api.saveHeartRateBloodPressureSettings, action.payload);
    yield put(Routines.saveHeartRateBloodPressureSettings.success(savedSettings));
    yield feedbackSuccess(Feedback.SAVED_HEART_RATE_BLOOD_PRESSURE_SETTINGS);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveManDownSettings.failure());
  }
}

function* requestHeartRate(action: AppAction<number>){
  try {
    yield call(Api.requestHeartRate, action.payload);
    yield put(Routines.requestHeartRate.success());
    yield feedbackSuccess(Feedback.REQUESTED_HEART_RATE);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.requestHeartRate.failure());
  }
}

function* requestBloodPressure(action: AppAction<number>){
  try {
    yield call(Api.requestBloodPressure, action.payload);
    yield put(Routines.requestBloodPressure.success());
    yield feedbackSuccess(Feedback.REQUESTED_BLOOD_PRESSURE);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.requestBloodPressure.failure());
  }
}

function* getHeartRateBloodPressure(action: AppAction<number>){
  try {
    const heartRateBloodPressures = yield call(Api.getHeartRateBloodPressure, action.payload);
    yield put(Routines.getHeartRateBloodPressure.success(heartRateBloodPressures));
  }
  catch(e){
    yield put(Routines.getHeartRateBloodPressure.failure());
  }
}

export type SaveBattery = { 
  deviceId: number;
  settings: BatteryForm;
}

function* saveBattery(action: AppAction<SaveBattery>){
  try{
    const savedBattery = yield call(Api.saveBattery, action.payload);
    yield put(Routines.saveBattery.success(savedBattery));
    yield feedbackSuccess(Feedback.SAVED_BATTERY);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveBattery.failure());
  }
}

export type SavePositioning = { 
  deviceId: number;
  settings: PositioningForm;
}

function* savePositioning(action: AppAction<SavePositioning>){
  try{
    const savedPositioning = yield call(Api.savePositioning, action.payload);
    yield put(Routines.savePositioning.success(savedPositioning));
    yield feedbackSuccess(Feedback.SAVED_POSITIONING);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.savePositioning.failure());
  }
}

export type SaveGeneralSettings = { 
  deviceId: number;
  settings: GeneralSettingsForm;
}

function* saveGeneralSettings(action: AppAction<SaveGeneralSettings>){
  try{
    const savedGeneralSettings = yield call(Api.saveGeneralSettings, action.payload);
    yield put(Routines.saveGeneralSettings.success(savedGeneralSettings));
    yield feedbackSuccess(Feedback.SAVED_GENERAL_SETTINGS);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveGeneralSettings.failure());
  }
}

export type SaveCommunication = {
  deviceId: number;
  settings: CommunicationForm;
}

function* saveCommunication(action: AppAction<SaveCommunication>){
  try{
    const savedCommunication = yield call(Api.saveCommunication, action.payload);
    yield put(Routines.saveCommunication.success(savedCommunication));
    yield feedbackSuccess(Feedback.SAVED_COMMUNICATION);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveCommunication.failure());
  }
}

function* fetchInactivity(action: AppAction<number>){
  try{
    const inactivity = yield call(Api.fetchInactivity, action.payload);
    yield put(Routines.fetchInactivity.success(inactivity));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.fetchInactivity.failure());
  }
}

export type SaveInactivity = {
  deviceId: number;
  settings: InactivitySettings;
}

function* saveInactivity(action: AppAction<SaveInactivity>){
  try{
    const inactivity = yield call(Api.saveInactivity, action.payload);
    yield put(Routines.saveInactivity.success(inactivity));
    yield feedbackSuccess(Feedback.SAVED_INACTIVITY);
  }
  catch(e){
    yield put(Routines.saveInactivity.failure());
    yield feedbackFailure(e);
  }
}

function* deleteTwin(action: AppAction<number>){
  try{
    const roles = yield select(getRoles);
    const device = yield call(Api.deleteTwin, action.payload, roles);
    yield put(Routines.deleteTwin.success(device));
    yield feedbackSuccess(Feedback.DELETED_TWIN);
  }
  catch(e){
    console.log(e)
    yield feedbackFailure(e);
    yield put(Routines.deleteTwin.failure());
  }
}

export type Simulate = {
  deviId: number;
  data: SimulateForm;
}

function* simulate(action: AppAction<Simulate>){
  try{
    yield call(Api.simulate, action.payload);
    yield put(Routines.simulate.success());
  }
  catch(e){
    yield put(Routines.simulate.failure());
  }
}

function* getStepData(action: AppAction<number>){
  try{
    const data: DevicePedometer[] = yield call(Api.getStepData, action.payload);
    yield put(Routines.getStepData.success(data));
  }
  catch(e){
    yield put(Routines.getStepData.failure());
  }
}

function* reroute(){
  location.hash = "safemates";
}

function* generalSaga(){
  yield takeLatest(Routines.fetchGeneral.TRIGGER, fetchGeneral);
  yield takeLatest(Routines.switchSafemate.TRIGGER, switchSafemate);
  yield takeLatest(Routines.resetSafemate.TRIGGER, resetSafemate);
  yield takeLatest(Routines.forgetSafemate.TRIGGER, forgetSafemate);
  yield takeLatest(Routines.clearWifiCache.TRIGGER, clearWifiCache);
  yield takeLatest(Routines.deleteSafemate.TRIGGER, deleteSafemate);
  yield takeLatest(Routines.saveAlarmSettings.TRIGGER, saveAlarmSettings);
  yield takeLatest(Routines.saveManDownSettings.TRIGGER, saveManDownSettings);
  yield takeLatest(Routines.saveHeartRateBloodPressureSettings.TRIGGER, saveHeartRateBloodPressureSettings);
  yield takeLatest(Routines.requestHeartRate.TRIGGER, requestHeartRate);
  yield takeLatest(Routines.requestBloodPressure.TRIGGER, requestBloodPressure);
  yield takeLatest(Routines.getHeartRateBloodPressure.TRIGGER, getHeartRateBloodPressure);
  yield takeLatest(Routines.saveBattery.TRIGGER, saveBattery);
  yield takeLatest(Routines.savePositioning.TRIGGER, savePositioning);
  yield takeLatest(Routines.saveGeneralSettings.TRIGGER, saveGeneralSettings);
  yield takeLatest(Routines.saveCommunication.TRIGGER, saveCommunication);
  yield takeLatest(Routines.fetchInactivity.TRIGGER, fetchInactivity);
  yield takeLatest(Routines.saveInactivity.TRIGGER, saveInactivity);
  yield takeLatest(Routines.deleteTwin.TRIGGER, deleteTwin);
  yield takeLatest(Routines.simulate.TRIGGER, simulate);
  yield takeLatest(Routines.getStepData.TRIGGER, getStepData);
}

export default generalSaga;
