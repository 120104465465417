import * as React from "react";
import { injectIntl, WrappedComponentProps, useIntl } from "react-intl";
import { Field } from "formik";
import styled from "styled-components";
import { Col, Row, Button } from "react-bootstrap";

import withFormWrapper from "@Elements/Form/formWrapper";
import Dropdown from "../../Elements/Dropdown/Dropdown";
import { Option } from "../../Elements/Dropdown/types";
import { CheckWrap } from "../Settings/Components/checkbox";
import { UserForm } from "./types";
import LogoModal from "../Modals/Logo";
import { RadioGroup, Radio } from "../../Elements/RadioFormik";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { sendGdprEmail, setStyle } from "./Store/routines";
import {DatabaseCountry, RoleWithCustomerNameWrapperDTO, RootState, User} from "./Store/types";
import {AhpRoles, GlobalRoleName} from "@Safemate/Policy/GlobalRoleName";
import {formatDate, formatDateOnly} from "@Safemate/Settings/Indoor/utils";
import { AppState } from "@Safemate/Store/types";
import { Checkbox } from "@Elements/CheckboxFormik";
import { MarketEnum } from "@Safemate/MarketEnum";
import { LogoEnum } from "@Safemate/Modals/Logo/LogoEnum";
import { IUser } from "@Safemate/Model/User";
import { SiteTypeEnum } from "@Safemate/SiteTypeEnum";



const NewsLetterLink = styled.a`
  color: ${props => props.theme.colors.accent};
`;

const Label = styled.label`
  text-transform: capitalize;
  display: block;
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  padding-bottom: 25px;
  .confirmPassword{
    label{
      text-transform: none;
    }
  }
`;

interface DisableableField extends WrappedComponentProps{
  disabled: boolean;
}

interface EmailProps extends WrappedComponentProps{
  emailAsUsername: boolean,
  ssoUser: boolean
}


export const EmailAsUsername = injectIntl(({intl: { formatMessage }}: WrappedComponentProps) => {
  const Check = CheckWrap<UserForm>("emailAsUsername", { label: {id: "emailAsUsername", defaultMessage: "Use email as username"}})
  return(
    <StyledRow>
      <Col md={12}>
        <Label>{formatMessage({id: "username", defaultMessage: "username"})}</Label>
        <Check/>
      </Col>
    </StyledRow>
  )
})

export const Email = injectIntl(({ intl: { formatMessage }, emailAsUsername, ssoUser }: EmailProps) => {
  return (
    <StyledRow>
      {emailAsUsername &&  
      <Col md={12}>
        <Label>{formatMessage({id: "emailPro", defaultMessage: "E-post"})}</Label>
        <StyledField
          type="text"
          name="username"
          disabled={ ssoUser }
        />
      </Col>      
      }
      {!emailAsUsername &&  
      <Col md={6}>
        <Label>{formatMessage({id: "username", defaultMessage: "Username"})}</Label>
        <StyledField
          type="text"
          name="username"
          disabled={ ssoUser }
        />
      </Col>
      }
      {!emailAsUsername &&  
      <Col md={6}>
        <Label>{formatMessage({id: "emailPro", defaultMessage: "E-post"})}</Label>
        <StyledField
          type="text"
          name="mailTo"
        />
      </Col>
      }
    </StyledRow>
  )
})

export const Name = injectIntl(({ intl: { formatMessage }, disabled }: DisableableField) => {
  return (
    <StyledRow>
      <Col md={6}>
        <Label>{formatMessage({id: "firstName", defaultMessage: "Fornavn"})}</Label>
        <StyledField
          type="text"
          name="firstName"
          disabled={disabled}
        />
      </Col>
      <Col md={6}>
        <Label>{formatMessage({id: "lastName", defaultMessage: "Etternavn"})}</Label>
        <StyledField
          type="text"
          name="lastName"
          disabled={disabled}
        />
      </Col>
    </StyledRow>
  )
})

export const Password = injectIntl(({ intl: { formatMessage } }: WrappedComponentProps) => {
  return (
    <StyledRow>
      <Col md={12}>
        <Label>{formatMessage({id: "password", defaultMessage: "Password"})}</Label>
        <StyledField
          type="password"
          name="password"
          placeholder={formatMessage({id: "password", defaultMessage: "Password"})}
        />
      </Col>
    </StyledRow>
  )
})

export const CurrentPassword = injectIntl(({ intl: { formatMessage } }: WrappedComponentProps) => {
  return (
    <StyledRow>
      <Col md={12}>
        <Label>{formatMessage({id: "currentPassword", defaultMessage: "Current Password"})}</Label>
        <StyledField
          type="password"
          name="currentPassword"
          placeholder={formatMessage({id: "currentPassword", defaultMessage: "Current Password"})}
        />
      </Col>
    </StyledRow>
  )
})

export const Confirm = injectIntl(({ intl: { formatMessage } }: WrappedComponentProps) => {
  return (
    <StyledRow>
      <Col md={12} className="confirmPassword">
        <Label>{formatMessage({id: "confirmPasswordPro", defaultMessage: "Bekreft passord"})}</Label>
        <StyledField
          type="password"
          name="confirm"
          placeholder={formatMessage({id: "confirmPasswordPro", defaultMessage: "Bekreft passord"})}
        />
      </Col>
    </StyledRow>
  )
})

interface MobileAndLanguageProps extends WrappedComponentProps{
  languageCode: string;
  sso: boolean;
  countries: DatabaseCountry[];
}

export const MobileAndLanguage = injectIntl(
  withFormWrapper<UserForm, MobileAndLanguageProps>(({ intl: { formatMessage }, formik: { setFieldValue }, languageCode, sso, countries}) => {
    const language = React.useMemo(() => {
      return countries.map((country: DatabaseCountry) => {
        return{
          value: `${country.languageCode}`,
          text: formatMessage({id: `${country.languageCode}`, defaultMessage: `${country.languageName}`})
        }
    })
    }, [languageCode])

    return (
      <StyledRow>
        <Col md={6}>
          <Label>{formatMessage({id: "mobile", defaultMessage: "Mobil"})}</Label>
          <StyledField
            type="text"
            name="mobile"
            disabled={sso}
          />
        </Col>
        <Col md={6}>
          <Label>{formatMessage({id: "language", defaultMessage: "Språk"})}</Label>
          <Dropdown
            options={language}
            onChange={(option) => {
              const { value } = option as Option;
              setFieldValue("languageCode", value);
            }}
            initial={languageCode}
            size={{width: "100%"}}
          />
        </Col>
      </StyledRow>
    )
  })
);

export const ApplyToAll = CheckWrap<UserForm>("applyToAll", {label: {id: "applyToAllRecipients", defaultMessage: "Endre også der dette nummeret er mottaker av varslinger"}});

export const NewsLetter = injectIntl(({ intl: { formatMessage } }: WrappedComponentProps) => 
  <StyledRow>
    <Col md={12}>
      <Label>{formatMessage({id: "newsletter", defaultMessage: "Nyhetsbrev"})}</Label>
      <span>
        {`${formatMessage({id: "myPageClick", defaultMessage: "Klikk"})} `}
        <NewsLetterLink href="https://www.safemate.no/nyhetsbrev/" target="_blank">
          {formatMessage({id: "myPageHere", defaultMessage: "her"})}
        </NewsLetterLink>
        {` ${formatMessage({id: "myPageToEnrollInTheNewslett", defaultMessage: "for å melde deg på nyhetsbrevene våre."})}`}
      </span>
    </Col>
  </StyledRow>
)

interface StyleProps{
  proffwebDark: boolean;
  setStyle: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToStyleProps = ({ appData: { user: { proffwebDark } } }: AppState) => {
  return{
    proffwebDark
  }
}

const mapDispatchToStyleProps = {
  setStyle
}

export const Style = connect(mapStateToStyleProps, mapDispatchToStyleProps)(({ proffwebDark, setStyle }: StyleProps) => {

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    changeStyleSheet(proffwebDark);
  }, [proffwebDark])

  return(
    <StyledRow>
      <Col md={12}>
        <Label>{formatMessage({id: "myPageStyle", defaultMessage: "Stil"})}</Label>
        <div style={{padding: "15px"}}>
          <Checkbox
            label={formatMessage({id: "useDarkCss", defaultMessage: "Mørk stil"})}
            value={proffwebDark}
            onChange={(value: any) => {
              setStyle(value.target.checked);
            }}
          />
        </div>
      </Col>
    </StyledRow>
  )
})

const cssDir = "proffweb/dist/css/";

enum STYLESHEET{
  STANDARD = "standard.css",
  STANDARD_WHITE = "standardWhite.css",
  LARGE = "large.css",
  LARGE_WHITE = "largeWhite.css",
  STANDARD_AHP = "standardAhp.css",
  STANDARD_AHP_WHITE = "standardAhpWhite.css",
  LARGE_AHP = "largeAhp.css",
  LARGE_AHP_WHITE = "largeAhpWhite.css"
}

const styleMap: Map<STYLESHEET, STYLESHEET> = new Map<STYLESHEET, STYLESHEET>([
  [STYLESHEET.STANDARD, STYLESHEET.STANDARD_WHITE],
  [STYLESHEET.LARGE, STYLESHEET.LARGE_WHITE],
  [STYLESHEET.STANDARD_AHP, STYLESHEET.STANDARD_AHP_WHITE],
  [STYLESHEET.LARGE_AHP, STYLESHEET.LARGE_AHP_WHITE],
  [STYLESHEET.STANDARD_WHITE, STYLESHEET.STANDARD],
  [STYLESHEET.LARGE_WHITE, STYLESHEET.LARGE],
  [STYLESHEET.STANDARD_AHP_WHITE, STYLESHEET.STANDARD_AHP],
  [STYLESHEET.LARGE_AHP_WHITE, STYLESHEET.LARGE_AHP],
])

const styleMapAhp: Map<STYLESHEET, STYLESHEET> = new Map<STYLESHEET, STYLESHEET>([
  [STYLESHEET.STANDARD, STYLESHEET.STANDARD_AHP],
  [STYLESHEET.STANDARD_AHP, STYLESHEET.STANDARD],
  [STYLESHEET.STANDARD_WHITE, STYLESHEET.STANDARD_AHP_WHITE],
  [STYLESHEET.STANDARD_AHP_WHITE, STYLESHEET.STANDARD_WHITE],
])

export const changeStyleSheet = (proffwebDark: boolean) => {

  document.cookie = `style=${proffwebDark ? "black" : "white"}`;
  const links = document.getElementsByTagName("link");
  for(let i = 0; i < links.length; i++){
    const href = links[i].getAttribute("href") || "";
    if(href.includes(cssDir)){

      if(href.includes("White") && !proffwebDark) return;
      if(!href.includes("White") && proffwebDark) return;

      const currentCssFile = href.replace(cssDir, "");
      let stylesheet : STYLESHEET = STYLESHEET.STANDARD;
      for (const [key, value] of Object.entries(STYLESHEET)) {
        if(value === currentCssFile){
          stylesheet = value;
        }
      }
      links[i].setAttribute("href", `${cssDir}${styleMap.get(stylesheet) || STYLESHEET.STANDARD}`)
    }
  }
}

export const changeAhpStyleSheet = (isAhp: boolean) => {
  const links = document.getElementsByTagName("link");
  for(let i = 0; i < links.length; i++){
    const href = links[i].getAttribute("href") || "";
    if(href.includes(cssDir)){

      if(href.includes("Ahp") && isAhp) return;
      if(!href.includes("Ahp") && !isAhp) return;

      const currentCssFile = href.replace(cssDir, "");
      let stylesheet : STYLESHEET = STYLESHEET.STANDARD;
      for (const [key, value] of Object.entries(STYLESHEET)) {
        if(value === currentCssFile){
          stylesheet = value;
        }
      }
      links[i].setAttribute("href", `${cssDir}${styleMapAhp.get(stylesheet) || STYLESHEET.STANDARD}`)
    }
  }
}

export const TfaEnabled = injectIntl(({intl: { formatMessage }}: WrappedComponentProps) => {
  const Check = CheckWrap<UserForm>("tfaEnabled", {label: {id: "enabled", defaultMessage: "Enabled"}})
  return(
    <StyledRow>
      <Col md={12}>
        <Label>{formatMessage({id: "tfaEnabled", defaultMessage: "Tofaktorautentisering på SMS"})}</Label>
        <Check/>
      </Col>
    </StyledRow>
  )
})

const ScrollBox = styled.div`
  color: black;
  border: 2px solid rgba(0, 0, 0, 0.1);
  height: 200px;
  padding: 10px;
  overflow-y: scroll;
`;

const GdprOkBtn = styled(Button)`
  width: 100%;
  height: 50px;
  display: flex;
  line-height: 50px;
  justify-content: center;
`;

const SendGdprEmailBtn = styled(Button)`
  width: 100%;
  height: 50px;
  display: flex;
  line-height: 50px;
  justify-content: center;
  margin-bottom: 10px;
`;

interface GdprProps extends WrappedComponentProps{
  sendGdprEmail: Routine<ActionFunctionAny<Action<any>>>;
  market: MarketEnum;
}

const mapStateToGdpProps = ({ appSettings: { market } }: AppState) => {
  return {
    market
  }
}

const mapDispatchFromStateGdpr = {
  sendGdprEmail
}


export const Gdpr = connect(mapStateToGdpProps, mapDispatchFromStateGdpr)(injectIntl(({ intl: { formatMessage }, market, sendGdprEmail }: GdprProps) => {

  const [ showGdpr, setShowGdpr ] = React.useState(false);

  const Check = CheckWrap<UserForm>("gdprPsAccepted", {label: {id: "accepted", defaultMessage: "Godtatt"}})

  const handleSendGdprEmail = (e:any) => {
    sendGdprEmail();
    e.target.disabled = true;
  }
  return(
    <StyledRow>
      <Col md={12}>
        <Label>
          {formatMessage({ id: "gdprPSTitle", defaultMessage: "Personvernerklæring" })}
          &nbsp;
          <Button onClick={() => setShowGdpr(true)} bsSize="xsmall">
            {formatMessage({id: "myPageShow", defaultMessage: "VIS"})}
          </Button>
        </Label>
        <Check/>
      </Col>
      <LogoModal
        logo={market === MarketEnum.SAFECALL ? LogoEnum.SAFECALL : LogoEnum.SAFEMATE}
        isOpen={showGdpr}
        title={formatMessage({id: "gdprPSTitle", defaultMessage: "Personvernerklæring"})}
        footer={
          <Col>
            <SendGdprEmailBtn
              id="sendMeEmailGdpr"
              onClick={(e) => handleSendGdprEmail(e)}
            >
              {formatMessage({id: "sendMeOnEmail", defaultMessage: "SEND MEG PÅ E-POST"})}
            </SendGdprEmailBtn>
            <GdprOkBtn
              onClick={() => setShowGdpr(false)}
            >
              {formatMessage({id: "ok", defaultMessage: "OK"})}
            </GdprOkBtn>
          </Col>

        }
      >
        <ScrollBox dangerouslySetInnerHTML={{ __html: formatMessage({id: "gdprPSText", defaultMessage: "GDPR Placeholder"})}}/>
      </LogoModal>
    </StyledRow>
  )
})
)


interface RolesWithCustomerNamesProps extends WrappedComponentProps{
  userWithRolesAndCustomers: RoleWithCustomerNameWrapperDTO;
  user: IUser;
  currentContext: SiteTypeEnum;
  ahpRoles: AhpRoles;
}

const rolesWithCustomerNamesStateToProps = ({ myPage: {user: {userWithRolesAndCustomers}}, appData: { user, currentContext, ahpRoles } }: AppState) => {
  return {
    userWithRolesAndCustomers,
    user,
    currentContext,
    ahpRoles
  }
}

export const RolesWithCustomerNames = connect(rolesWithCustomerNamesStateToProps, null)(injectIntl(({ intl: { formatMessage }, userWithRolesAndCustomers, ahpRoles, currentContext, user }: RolesWithCustomerNamesProps) => {
  
  if(currentContext === SiteTypeEnum.AHP){
    return(
      <StyledRow>
        <Col md={12}>
          <Label>{formatMessage({id: "myPageRoles", defaultMessage: "Roles"})}</Label>
          <span>
            {formatMessage({id: ahpRoles.SUPERAHP ? GlobalRoleName.SUPERFIRM : GlobalRoleName.FIRM, defaultMessage: ahpRoles.SUPERAHP ? GlobalRoleName.SUPERFIRM : GlobalRoleName.FIRM})}
          </span>
        </Col>
      </StyledRow>
    )
  }
  
  return(
      <StyledRow>
        <Col md={12}>
          <Label>{formatMessage({id: "myPageRoles", defaultMessage: "Roles"})}</Label>
          <span>
            {user.firstName && user.firstName} {user.lastName} {`${formatMessage({id: "myPageRolesText1", defaultMessage: "have role"})} `}
            { userWithRolesAndCustomers.permanent.globalRole ? formatMessage({id: userWithRolesAndCustomers.permanent.roleName, defaultMessage: userWithRolesAndCustomers.permanent.roleName}) : userWithRolesAndCustomers.permanent.roleName }
            {` ${formatMessage({id: "myPageRolesText2", defaultMessage: "in"})} `}
            { userWithRolesAndCustomers.permanent.customerNames.join(", ") }{`. `}
          </span>
          {
            userWithRolesAndCustomers.temporary &&
            <span>
            {user.firstName && user.firstName} {user.lastName} {`${formatMessage({id: "myPageTempRolesText1", defaultMessage: "have temporary role"})} `} { userWithRolesAndCustomers.temporary.globalRole ? formatMessage({id: userWithRolesAndCustomers.temporary.roleName, defaultMessage: userWithRolesAndCustomers.temporary.roleName}) : userWithRolesAndCustomers.temporary.roleName } {`${formatMessage({id: "myPageRolesText2", defaultMessage: "in"})} `} { userWithRolesAndCustomers.temporary.customerNames.join(", ") }
              {` ${formatMessage({id: "myPageTempRolesText2", defaultMessage: "valid till"})} ${formatDateOnly(userWithRolesAndCustomers.temporary.tempTo)}.`}
          </span>
          }
        </Col>
      </StyledRow>
    )
  })
)


export enum TfaType{
  SMS = "SMS",
  EMAIL = "EMAIL"
}


export const Tfa = injectIntl(
  withFormWrapper<UserForm, WrappedComponentProps>(({intl: { formatMessage }, formik: { values: { tfaEmail }}}) => {
    return(
      <StyledRow>
        <Col md={12}>
          <Label>{formatMessage({id: "twoFactorAuth", defaultMessage: "Two Factor Authentication"})}</Label>
          <RadioGroup disabled={false}>
            <Field
              component={Radio}
              name="tfaEmail"
              radioValue={TfaType.SMS}
              id="tfaSMS"
              label={formatMessage({id: 'codeSentSMS', defaultMessage: 'Code sent via SMS'})}
            />
            <Field
              component={Radio}
              name="tfaEmail"
              radioValue={TfaType.EMAIL}
              id="tfaEmail"
              label={formatMessage({id: 'codeSentEMail', defaultMessage: 'Code sent via E-Mail'})}
            />
          </RadioGroup>
        </Col>
      </StyledRow>
    )
}))

interface InputFieldProps extends WrappedComponentProps{
  name: string;
  type: string;
  label: string;
  placeholder?: string;
}

const InputField = injectIntl(
  withFormWrapper<UserForm, InputFieldProps>(({name, type, label, placeholder}) => {
    return(
      <React.Fragment>
        <Label>{label}</Label>
        <Field
          name={name}
          type={type}
          placeholder={placeholder}
        />
      </React.Fragment>
    )
  })
)

export default InputField;
