import { GeneralState } from "../types";
import {Reducer} from "redux";
import { fetchInactivity, fetchGeneral, saveAlarmSettings, saveBattery, savePositioning, saveGeneralSettings, saveManDownSettings , saveHeartRateBloodPressureSettings, getHeartRateBloodPressure, saveSoundLight, saveInactivity, getStepData} from "../routines";
import {GeneralDTO} from "../../General/types";

import { SoundLightSettings } from "../../types";

const generalState: GeneralState = {
  inactivity: {
    inactivityMode: 0,
    inactivityWarnTime: 0,
    inactivityFunction: false,
    inactivityFromOne: "",
    inactivityToOne: "",
    inactivityIntervalOne: 0,
    inactivityFromTwo: "",
    inactivityToTwo: "",
    inactivityIntervalTwo: 0
  },
  generalSettings: {
    enableOffButton: false,
    staticTooLong: "",
    vibration: false,
    stepCounter: false,
    aclCheck: true,
    healthHeartRateBloodPressure: false,
    watchRemoval: false,
    sensorSupervisionCheckActive: false,
    sensorSupervisionCheckTimer: 0,
    sensorSupervisionCheckTimerPendant: 0,
    heartbeatInterval: 0,
    guardTime: 0,
    alarmTimeout: 0,
    helpArrive: 0,
    localLearning: false,
    bypassTimer: 0
  },
  positioning: {
    disablePositioning: false,
    minNumSatellites: 0
  },
  battery: {
    batteryThreshold: "0"
  },
  alarmSettings: {
    buttonBehaviour: "",
    buttonSensitivity: "",
    callAlert: "false",
    fallDetection: null,
    silentAlarm: "",
    sosCancellation: true,
    voicePromptSosAlarm: true,
    vibration: false,
    teamAlert: false,
    teamAlertLearnUrl: ""
  },
  manDownSettings: {
    fallDownAlert: false,
    fallDownSensitivity: 0,
    tiltAlert: false,
    tiltAngle: 0,
    tiltTime: 0,
    watchTwo: false
  },
  heartRateBloodPressureSettings: {
    heartRateBloodPressureSetting: false,
    heartRateInterval: 60,
    bloodPressureInterval: 60,
    calibrateBloodPressureSetting: false,
    calibrateSystolicPressure: 72,
    calibrateDiastolicPressure: 120,
    openPopup: false
  },
  heartRateBloodPressures: [],
  staticTooLongDefault: "",
  gpsSatCriteriaWithDefault: "",
  communication: {
    prefComMethod: 0,
    twoWayDECTTimer: 0,
    answerIncoming: 0,
    silentActive: false,
    externalAntenna: 0,
    callbackTimer: 0
  },
  soundLight: {
    micVolume: "",
    soundLevel: "",
    soundLevelRingtone: "",
    voicePromptVolume: "",
    lightLevel: "",
    lightMode: "",
    led: "",
    soundOnOff: false,
    ringingOnOff: false,
    screenBrightness: "",
    lowBatteryVoicePrompt: false,
    screenLightMode: "",
    volumeLevel: 0,
    dectVolumeLevel: 0,
    twoWayMic: 0,
    promptVol: 0,
    guardTimeBeep: "",
    confirmBeep: "",
    wandererBeep: false,
    helpArriveIndicator: false,
    callbackIndicator: false,
    synchronizeSound: false
  },
  allowDeviceDeletes: false,
  stepData: []
};

const general: Reducer<GeneralState> = (state: GeneralState = generalState, action: any) => {
  switch (action.type) {
    case fetchGeneral.SUCCESS:
      return mapSettings(action.payload, state);

    case saveAlarmSettings.SUCCESS:
      return{
        ...state,
        alarmSettings: action.payload
      }
    case saveManDownSettings.SUCCESS:
      return{
        ...state, 
        manDownSettings: {
          fallDownAlert: action.payload.fallDownAlert,
          fallDownSensitivity: state.manDownSettings.watchTwo ? !action.payload.fallDownAlert ? "0" : action.payload.fallDownSensitivity.toString() : action.payload.fallDownSensitivity,
          tiltAlert: action.payload.tiltAlert,
          tiltAngle: action.payload.tiltAngle,
          tiltTime: action.payload.tiltTime,
          watchTwo: state.manDownSettings.watchTwo,
        }
      }
    case saveHeartRateBloodPressureSettings.SUCCESS:
      return{
        ...state,
        heartRateBloodPressureSettings: action.payload,
      }
    case getHeartRateBloodPressure.SUCCESS:
      return{
        ...state,
        heartRateBloodPressures: action.payload
      }

    case saveBattery.SUCCESS:
      return{
        ...state,
        battery: action.payload
      }

    case savePositioning.SUCCESS:
      return{
        ...state,
        positioning: action.payload
      }

    case saveGeneralSettings.SUCCESS:
      return{
        ...state,
        generalSettings: action.payload
      }

    case saveSoundLight.SUCCESS:
      return {
        ...state,
        soundLight: {...mapSound(action.payload)}
      }

    case fetchInactivity.SUCCESS:
      return {
        ...state,
        inactivity: action.payload
      }

    case saveInactivity.SUCCESS:
      return {
        ...state,
        inactivity: action.payload
      }

    case getStepData.SUCCESS:
      return {
        ...state,
        stepData: action.payload
      }

    default:
        return state;
  }
};

export default general;

type MapSound = (sound: SoundLightSettings) => SoundLightSettings;

const mapSound: MapSound = (sound) => {

  return{
    ...sound,
    confirmBeep: sound.confirmBeep === "loud" || sound.confirmBeep === true,
    guardTimeBeep: sound.guardTimeBeep === "loud" || sound.guardTimeBeep === true
  }
}

type MapSettings = (data: GeneralDTO, currentState: GeneralState) => GeneralState;

const mapSettings: MapSettings = (data, currentState) => {
  return {
    ...currentState,
    communication: data.communication,
    generalSettings: data.generalSettings,
    soundLight: data.soundLight,
    battery: data.battery,
    positioning: {
      disablePositioning: data.disablePositioning,
      minNumSatellites: data.minNumSatellites
    },
    alarmSettings: {
      buttonBehaviour: data.buttonBehaviour,
      buttonSensitivity: data.buttonSensitivity,
      callAlert: data.callAlert,
      fallDetection: !!data.fallDetection,
      silentAlarm: data.silentAlarm,
      sosCancellation: data.sosCancellation,
      voicePromptSosAlarm: data.voicePromptSosAlarm,
      vibration: data.generalSettings.vibration,
      teamAlert: data.teamAlert,
      teamAlertLearnUrl: data.teamAlertLearnUrl
    },
    manDownSettings: {
      fallDownAlert: data.fallDownAlert,
      fallDownSensitivity: data.watchTwo ? !data.fallDownAlert ? "0" : data.fallDownSensitivity.toString() : data.fallDownSensitivity,
      tiltAlert: data.tiltAlert,
      tiltAngle: data.tiltAngle,
      tiltTime: data.tiltTime,
      watchTwo: data.watchTwo,
    },
    heartRateBloodPressureSettings:{
      heartRateBloodPressureSetting: data.heartRateBloodPressureSetting,
      heartRateInterval: data.heartRateInterval,
      bloodPressureInterval: data.bloodPressureInterval,
      calibrateBloodPressureSetting: data.calibrateBloodPressureSetting,
      calibrateSystolicPressure: data.calibrateSystolicPressure,
      calibrateDiastolicPressure: data.calibrateDiastolicPressure,
      openPopup: false
    },
    heartRateBloodPressures: currentState.heartRateBloodPressures,
    staticTooLongDefault: data.staticTooLongDefault,
    gpsSatCriteriaWithDefault: data.gpsSatCriteriaWithDefault,
    allowDeviceDeletes: data.allowDeviceDeletes
  }
}
