import React from 'react';

import {Header} from '../../UserAdmin/options';
import { GlobalRoles } from '../../GlobalRoles';
import { DeviceStatus, statusPriority, StatusType } from './deviceStatus';
import { Status, GsmIcon, DeviceIcon, BatteryIcon, TrackingIcon, RowInfo, DeviceListTimeline, SerialNumber, LastMessagedate, LastMessageTime, DeviceName, MunicialityImage, Twin } from './TableElements';
import { DeviceTypeToIcon } from './deviceIcon';
import { BatteryLevelToIcon } from './deviceBattery';
import Actions from './Actions';
import unknown from "../../../Elements/Icon/Icons/DeviceIcons/unknown";
import { IDevice } from '@Safemate/Model/Device';
import {AlarmAnalysisState} from "@Safemate/DeviceList/Store/types";

interface ListElement{
  text: React.ReactNode | string;
  priority?: number;
  hide?: boolean;
  colspan?: number;
}

export type MapHeader = (role: GlobalRoles) => Array<Header>;
export type MapDevices = (devices: IDevice[]) => ListElement[][]
export type GetStatus = (device: IDevice, beta: boolean) => DeviceStatus;
export type GetIcon = (dehaId: number) => React.ReactNode | undefined;
export type GetBatteryIcon = (battery: number, charging: boolean) => React.ReactNode | undefined;

export interface MappedDevice{
  deviceId: number;
  status: React.ReactNode;
  deviceIcon: React.ReactNode;
  deviceSNNum:React.ReactNode;
  deviceName: React.ReactNode;
  gsm: React.ReactNode;
  battery: React.ReactNode;
  tracking: React.ReactNode;
  lastdate: React.ReactNode;
  lasttime: React.ReactNode;
  actions: React.ReactNode;
  info: React.ReactNode;
  timeline?: React.ReactNode;
  twin?: React.ReactNode;
  [index: string]: React.ReactNode | number;
}

export const mapDevices = (isAhp: boolean, isPrivate: boolean, devices: IDevice[], beta: boolean, alarmAnalysis: AlarmAnalysisState, timelineCallback: () => void, twinSuffix: string) => {
  const mappedRows: MappedDevice[] = [];
  const alarmRows: MappedDevice[] = [];
  const cloneDevices: number[] = [];
  const masterDevices: number[] = [];
  devices.map((device: IDevice) => {
    
    const isTwin = !!device.deviceTwin;
    let isTwinNotMaster = false;
    const deviceRow = mapDevice(device, beta, isPrivate, isAhp);
    let twinRow;
    let deviceTwin;

    if(isTwin){
      if(device.deviId !== device.deviceTwin.masterDevice){
        isTwinNotMaster = true;
      }
    }

    if(isTwin){
      if(device.deviceTwin.device == null){
        deviceTwin = devices.find(d => d.deviId === device.deviceTwin.deviId);
        if(device.deviceTwin.masterDevice === device.deviId){
          deviceTwin.deviceName = `${device.deviceName} ${twinSuffix}`;
        }
        twinRow = mapDevice(deviceTwin, beta, isPrivate, isAhp);

      }else{
        deviceTwin = device.deviceTwin.device;
        twinRow = mapDevice(deviceTwin, beta, isPrivate, isAhp);
      }


      deviceRow.twin = <Twin twin={TwinType.MASTER}></Twin>;
      twinRow.twin = <Twin twin={TwinType.CLONE} alarm={deviceTwin[StatusType.ALARM_WARNING]}></Twin>;
      
      if(deviceTwin[StatusType.ALARM_WARNING]){
        twinRow.timeline = <DeviceListTimeline alarmAnalysis={alarmAnalysis} renderCallback={timelineCallback} id={deviceTwin.deviId} canvasProperties={{margin: 10, textSize: 13, notes: false}}/>;
      }
    }

    if(device[StatusType.ALARM_WARNING]){
      if(!isTwinNotMaster){
        deviceRow.timeline = <DeviceListTimeline alarmAnalysis={alarmAnalysis}  renderCallback={timelineCallback} id={device.deviId} canvasProperties={{margin: 10, textSize: 13, notes: false}}/>;
        alarmRows.push(deviceRow);
        if(isTwin){
          alarmRows.push(twinRow);
        }
      }
    }
    else if(isTwin && deviceTwin[StatusType.ALARM_WARNING]){
      if(!isTwinNotMaster){
        alarmRows.push(deviceRow);
        alarmRows.push(twinRow);
      }

    }
    else if(isTwin){
      if(!isTwinNotMaster){
        mappedRows.push(deviceRow);
        mappedRows.push(twinRow);
      }
    }
    else{
      mappedRows.push(deviceRow);
    }
    deviceTwin = null;
    if(device.deviceTwin){
      cloneDevices.push(device.deviceTwin.deviId);
      masterDevices.push(device.deviceTwin.masterDevice);
    }
  })
  return { mappedRows, alarmRows, cloneDevices, masterDevices };
}

const mapDevice = (device: IDevice, beta: boolean, isPrivate: boolean, isAhp: boolean): MappedDevice => {
  return {
    deviceId: device.deviId,
    status: <Status device={device} beta={beta} isAhp={isAhp}/>, 
    deviceIcon:  <DeviceIcon deviceHardware={device.dehaId} deviceTypeName={device.detyName}/>,
    deviceSNNum: <SerialNumber serialNumber={device.deviceSNNum}/>,
    municialityImage: <MunicialityImage customerId={device.ownedByCustId}/>,
    deviceName: <DeviceName name={device.deviceName}/>,
    gsm: <GsmIcon dehaId={device.dehaId} gsm={device.gsmStrength} isOnline={!device.offline && !device.tcpStatusDisabledWarning}/>,
    battery: <BatteryIcon dehaId={device.dehaId} battery={device.battery} charging={device.charging}/>,
    tracking: <TrackingIcon tracking={device.tracking} deviceHardware={device.dehaId}/>,
    lastdate: <LastMessagedate lastMsgIn={device.lastMsgIn}/>,
    lasttime: <LastMessageTime lastMsgIn={device.lastMsgIn}/>,
    actions: <Actions device={device}/>,
    info: <RowInfo isPrivate={isPrivate} device={device}/>
  }
}

export const getStatus: GetStatus = (device, beta) => {
  return statusPriority.find(status => {
      return (device[status.type] === status.check) || status.type === StatusType.DEFAULT
  })!.status;
}

export const getIcon: GetIcon = (dehaId) => {
  return DeviceTypeToIcon.get(dehaId)===undefined ? DeviceTypeToIcon.get(0): DeviceTypeToIcon.get(dehaId);
}

export const getBatteryIcon: GetBatteryIcon = (battery, charging) => {
  const batteryLevel = Math.max(1, Math.ceil(battery / 25));
  const batteryIcon = BatteryLevelToIcon.get(batteryLevel);
  if(!batteryIcon) return;
  if(charging) return batteryIcon.charging;
  return batteryIcon.normal;
}

export enum TwinType{
  NONE = "NONE",
  MASTER = "MASTER",
  CLONE = "CLONE"
}
