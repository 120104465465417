import * as React from "react";
import {Motion, spring} from "react-motion";
import uuid from "uuid";

import {TreeDropdownOption} from "./types";
import {Checkbox} from "../../Elements/CheckboxFormik";
import {Collapse, UnmountClosed} from "react-collapse";
import { 
  SelectWrapper, 
  Select, 
  TitleContainer, 
  Caret, 
  SelectBox, 
  StyledLi,
  ExpandDiv, 
  ListElement, 
  Label, 
  Triangle 
} from './styles';
import { useComponentVisible } from './utils';

interface TreeDropdownProps{
  options: Array<TreeDropdownOption>;
  onChange: (values: Array<string>) => void;
  sessionKey?: string;
  title?: string;
  multiSelect?: boolean;
  disabled?: boolean;
}

interface Selected{
  [index: string]: SelectedOption;
}
interface SelectedOption{
  name: string;
  key: string;
  checked: boolean;
}

const TreeDropdown = ({title: initialTitle, sessionKey, options: initialOptions, onChange, multiSelect, disabled}: TreeDropdownProps) => {

  const { selectRef, ref, isComponentVisible, setIsComponentVisible } = useComponentVisible<HTMLUListElement, HTMLDivElement>(false);

  const [options, setOptions] = React.useState(initialOptions);
  const [selected, setSelected] = React.useState({} as Selected);

  let timeoutId: NodeJS.Timeout;

  React.useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);


  let localSelected: Selected = {}
  React.useEffect(() => {
    const localSelected: Selected = {};
    const findSelected = (opts: Array<TreeDropdownOption> | null): void => {
      opts && opts.forEach(opt => {
        localSelected[opt.key] = {name: opt.name, key: opt.key, checked: opt.checked};
        findSelected(opt.children);
      })
    };
    findSelected(initialOptions);
    setSelected(localSelected);
  }, [initialOptions]);

  const handleChange = (key: string, name: string, checked: boolean) => {
    localSelected ={...selected, ...localSelected};
    
    if(!multiSelect){
      for (let key in localSelected) {
        localSelected[key].checked = false;
      }
    }
    localSelected[key] =  {key, name, checked};

    setSelected({...localSelected});
    onChange(getSelectedKeys(localSelected));
    if(!multiSelect){
      setIsComponentVisible(false);
    }
  };

  const getSelectedKeys = (currentSelected: Selected): string[] => {
    return Object.values(currentSelected).map(item => {
      return item.checked && item.key
    }).filter(item => item) as Array<string>;
  }

  const getSelectedNames = (currentSelected: Selected) => {
    return Object.values(currentSelected).map(item => {
      return item.checked && item.name
    }).filter(item => item) as Array<string>;
  }

  React.useEffect(() => {
    // Reset state to initial if the session key is changed
    setOptions(initialOptions);
  }, [sessionKey]);

  const title = React.useMemo(() => {
    const names = getSelectedNames(selected);
    if(names.length > 2){
      return `${names.length} valgte`;
    }
    else if(names.length > 0){
      return names.join(", ");
    }
    return initialTitle;
  }, [selected]);

  const handleBlur = () => {
    timeoutId = setTimeout(() => {
      setIsComponentVisible(false);
    });
  }

  const handleFocus = () => {
    clearTimeout(timeoutId);
  }

  const [motionDisplay, setMotionDisplay] = React.useState(isComponentVisible);

  return(
    <SelectWrapper
      disabled={!!disabled}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => event.key === "Escape" && setIsComponentVisible(false)}
    >
      <Select
        active={isComponentVisible}
        ref={selectRef}
        onClick={() => {
          if(!isComponentVisible)
            setMotionDisplay(true);
          setIsComponentVisible(!isComponentVisible);
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
          if(event.key === "Enter"){
            if(!isComponentVisible)
              setMotionDisplay(true);

            setIsComponentVisible(!isComponentVisible)
          }

        }}
        tabIndex={0}
      >
        <TitleContainer>{title}</TitleContainer>
        <TitleContainer right>
          <Triangle
            initial={isComponentVisible}
            animate={{
              rotate: isComponentVisible ? 180 : 0,
              opacity: isComponentVisible ? 1 : 0.5,
              scale: isComponentVisible ? 1 : 0.5
            }}
          />
        </TitleContainer>
      </Select>
      <SelectBox
        initial={false}
        animate={{
          maxHeight: isComponentVisible ? 250 : 0,
          width: isComponentVisible ? 1 : 0,
          opacity: isComponentVisible? 1 : 0
        }}
        ref={ref}
      >
        <List selected={selected} handleChange={handleChange} options={options} multi={!!multiSelect}/>
      </SelectBox>
    </SelectWrapper>
  )
};

interface ListProps{
  options: Array<TreeDropdownOption>;
  handleChange: (key: string, name: string, checked: boolean) => void;
  multi: boolean;
  selected: Selected;
}

const List = ({options, selected, ...rest}: ListProps) => {

  const isChildrenChecked = (opts: Array<TreeDropdownOption> | null, selected: Selected): boolean => {
    if(opts === null) return false;
    if(opts.find(opt => opt.checked || (selected[opt.key] && selected[opt.key].checked))){
      return true;
    }
    let isChildChecked = false;
    let i = 0;
    while(!isChildChecked && i < opts.length){
      isChildChecked = isChildrenChecked(opts[i].children, selected);
      i++;
    }
    return isChildChecked;
  }

  const Elements = React.useMemo(() => {
    const temp = (opts: Array<TreeDropdownOption> | null, level: number, show: boolean): Array<any> => {
      return opts ? opts.map(opt => {
        const remappedOpt: TreeDropdownOption = {
          ...opt,
          checked: selected[opt.key] ? selected[opt.key].checked : opt.checked,
          showChildren: isChildrenChecked(opt.children, selected) || (!rest.multi && opt.showChildren)
        }
        return [
          <ListItem expandKey={uuid.v4()} checkKey={uuid.v4()} locked={opt.locked} show={show} checked={remappedOpt.checked} item={remappedOpt} level={level} {...rest}>
            {temp(opt.children, level + 1, remappedOpt.showChildren)}
          </ListItem>
        ]
      }).flat() : [];
    }
    return temp(sortTree(options), 1, true);
  }, [options]);

  return(
    <React.Fragment>
      {Elements}
    </React.Fragment>
  )
}

function sortTree(tree: TreeDropdownOption[]): TreeDropdownOption[] {
  // Sort the current level based on the 'name' property
  tree.sort((a, b) => a.name.localeCompare(b.name));

  // Recursively sort each child
  tree.forEach(node => {
      if (node.children && node.children.length > 0) {
          node.children = sortTree(node.children);
      }
  });

  return tree;
}

interface ListItemProps{
  item: TreeDropdownOption;
  level: number;
  multi: boolean;
  show: boolean;
  checked: boolean;
  locked: boolean;
  handleChange: (key: string, name: string, checked: boolean) => void;
  checkKey: string;
  expandKey: string;
  parentChange?: boolean;
}

interface ListItemState{
  expanded: boolean;
  checked: boolean;
  forceChild: boolean | undefined;
  key: {check: string, expand: string};
}

type Action =
  | {type: "check"; state: boolean}
  | {type: "expand"; state: boolean}
  | {type: "click"; state: boolean}

const listItemReducer = (state: ListItemState, action: Action) => {
  switch(action.type){
    case "check":
      return {
        ...state,
        key: {
          ...state.key,
          check: uuid.v4()
        },
        checked: action.state,
        forceChild: true
      }
    case "expand":
      return{
        ...state,
        key: {
          ...state.key,
          expand: uuid.v4()
        },
        expanded: action.state
      }
    case "click":
      return{
        ...state,
        forceChild: action.state
      }
  }
}

const ListItem = ({item, level, multi, handleChange, checkKey, expandKey, locked, children, parentChange, checked: initialChecked, show}: React.PropsWithChildren<ListItemProps>) => {

  const initialState: ListItemState = {
    expanded: item.showChildren,
    checked: parentChange ? initialChecked : item.checked,
    forceChild: parentChange,
    key: {
      check: checkKey,
      expand: expandKey
    }
  };

  const [{expanded, checked, forceChild, key: {check, expand}}, dispatch] = React.useReducer(listItemReducer, initialState);

  React.useEffect(() => {
    if(checkKey !== check && multi) {
      const isChecked = parentChange && initialChecked ? initialChecked : item.checked;
      dispatch({type: "check", state: isChecked});
      handleChange(item.key, item.name, isChecked);
    }
  }, [checkKey]);

  React.useEffect(() => {
    if(expandKey !== expand){
      dispatch({type: "expand", state: show});
    }
  }, [expandKey]);

  React.useEffect(() => {
    if(multi)
      dispatch({type: "click", state: !!parentChange});
  }, [parentChange]);

  const handleCheck = () => {
    if(multi){
      dispatch({type: "check", state: !checked});
      handleChange(item.key, item.name, !checked);
    }
    else{
      handleChange(item.key, item.name, true);
    }
  };

  const handleExpand = () => {
    dispatch({type: "expand", state: !expanded});
  };

  const lastChild = !item.children || item.children.length === 0;

  return(
    <React.Fragment>
      <UnmountClosed isOpened={show}>
          <StyledLi tabIndex={0} disabled={item.disabled} key={`${item.key}-${level}`}>
            <ExpandDiv
                last={lastChild}
                tabIndex={item.disabled || lastChild ? undefined : 0}
                onKeyDown={(event) => {
                  if(event.key === "Enter" && !item.disabled) {
                    handleExpand();
                  }
                }}
                onClick={() => {
                  if (!item.disabled) {
                    handleExpand();
                  }
                }}
                level={level}
            >
              {!lastChild && <Caret open={expanded} last={lastChild}/>}
            </ExpandDiv>
            <ListElement
              onKeyDown={(event) => {
                if(event.key === "Enter" && !locked && !item.disabled){
                  handleCheck();
                }
              }}
              onClick={() => {
                if ((!locked || !multi) && !item.disabled) {
                  handleCheck();
                }
              }}
            >
              {multi
                ? <Checkbox
                  name={item.name}
                  value={checked}
                  label={item.name}
                  disabled={item.disabled}
                  readOnly
                />
                : <Label>{item.name}</Label>
              }
            </ListElement>
          </StyledLi>
      </UnmountClosed>
      {children && React.Children.map(children, (child => {
        return React.cloneElement(child as React.ReactElement, {
          show: expanded || (item.disabled && item.showChildren),
          parentChange: forceChild,
          checked,
          checkKey: check,
          expandKey: expand,
          locked: checked
        })
      }))}
    </React.Fragment>
  )
}

export default TreeDropdown;
