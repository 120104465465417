import * as React from "react";

import {injectIntl, useIntl, WrappedComponentProps} from "react-intl";
import { SoundLightSettings } from "../../types";
import { SettingEnum } from "../../../DefaultSettings/Settings/settingEnum";
import { RadioGroup, Radio } from "../../../../Elements/RadioFormik";
import { Field } from "formik";
import { VerticalRadioGroup } from "./style";
import { connect } from "react-redux";
import {DeviceHardwareEnum} from "../../../DeviceHardwareEnum";
import {ValueSelector} from "./valueSelector";
import { CheckWrap } from "@Elements/Form/CheckWrap";
import {AppState, Language} from "@Safemate/Store/types";
import {DatabaseCountry} from "@Safemate/MyPage/Store/types";
import {Col, Row} from "react-bootstrap";
import Dropdown from "@Elements/Dropdown/Dropdown";
import {Option} from "@Elements/Dropdown/types";
import withFormWrapper from "@Elements/Form/formWrapper";
import {AlarmSettingsForm} from "@Safemate/Settings/General/types";
import {ToggleWrapper} from "@Safemate/Settings/General/Tabs/heartRateBloodPressure";
import {
  InputWrapperNoBorder,
  StyledH3PL,
  StyledPPadNoMargin,
  TimeBtn
} from "@Safemate/Settings/General/Tabs/alarmSettings";
import styled from "styled-components";
import {Capabilities} from "@Safemate/Settings/Store/types";
import {ContentWrap} from "@Safemate/DefaultSettings/Settings/styled";

const Label = styled.label`
  text-transform: capitalize;
  display: block;
`;

export const SoundsForCharging = CheckWrap<SoundLightSettings>(SettingEnum.SOUND_ON, {label: {id: "systemSounds", defaultMessage: "Lyder ifm. lading og lavt batteri"}});

export const IncomingRingTone = CheckWrap<SoundLightSettings>(SettingEnum.RINGING_ON, {label: {id: "ringTone", defaultMessage: "Ringelyd ved innkommende anrop"}});

export const Led = CheckWrap<SoundLightSettings>(SettingEnum.LED, {label: {id: "enableLed", defaultMessage: "LED aktivert"}});

export const NotApplicable = injectIntl(({intl: {formatMessage}}: WrappedComponentProps) => <div>{formatMessage({id: "notApplicableForThisDevice", defaultMessage: "Denne innstillingen er ikke i bruk for denne enheten."})}</div>)

interface LightModeProps extends WrappedComponentProps{
    fiwaNum: number
}

const mapLightModeStateToProps = ({ settings: {device: { device: { fiwaNum }}}}: AppState) => {
    return {
        fiwaNum
    }
}

export const LightMode = connect(mapLightModeStateToProps)(injectIntl(({ intl: {formatMessage}, fiwaNum }: LightModeProps) => {
    if(fiwaNum <= 2) return <NotApplicable/>
    return(
        <RadioGroup disabled={false}>
            <VerticalRadioGroup>
                <Field
                    component={Radio}
                    name={SettingEnum.LIGHT_MODE}
                    radioValue="1"
                    id="light-mode-1"
                    label={formatMessage({id: "testmode", defaultMessage: "Testmodus"})}
                />
                <Field
                    component={Radio}
                    name={SettingEnum.LIGHT_MODE}
                    radioValue="2"
                    id="light-mode-2"
                    label={formatMessage({id: "normalmode", defaultMessage: "Normalmodus"})}
                />
                <Field
                    component={Radio}
                    name={SettingEnum.LIGHT_MODE}
                    radioValue="3"
                    id="light-mode-3"
                    label={formatMessage({id: "off", defaultMessage: "Av"})}
                />
            </VerticalRadioGroup>
        </RadioGroup>)
}))

export const LowBatteryVoicePrompt = CheckWrap<SoundLightSettings>(SettingEnum.LOW_BATTERY_VOICE_PROMPT, {label: {id: "lowBatteryVoicePrompt", defaultMessage: "Low battery voice prompt"}});

interface DehaFirmNumProps extends WrappedComponentProps{
  fiwaNum: number;
  dehaId: number;
}

const mapDehaFirmNumPropsStateToProps = ({ settings: {device:{device: {fiwaNum, dehaId}}}}: AppState) => {
  return {
    fiwaNum,
    dehaId,
  }
}

export const ScreenBrightness = connect(mapDehaFirmNumPropsStateToProps)(injectIntl(({ intl: {formatMessage}, fiwaNum, dehaId }: DehaFirmNumProps) => {
  return(
    <React.Fragment>
      { ((dehaId === DeviceHardwareEnum.WATCH_TWO && fiwaNum > 1) || dehaId !== DeviceHardwareEnum.WATCH_TWO ) && <React.Fragment>
        <ValueSelector
          title={{ id: "screenBrightness", defaultMessage: "Screen brightness" }}
          name="screenBrightness"
          startLevel={1}
          count={5}
        />
        <br/>
        <p>{formatMessage({id: "screenBrightnessWarning", defaultMessage: "Higher brightness may drain the battery faster and cause wrist discomfort from heat."})}</p>
        </React.Fragment>}
    </React.Fragment>
  )
}))

export const ScreenLightMode = connect(mapDehaFirmNumPropsStateToProps)(injectIntl(({ intl: {formatMessage}, fiwaNum, dehaId }: DehaFirmNumProps) => {
  return(
    <React.Fragment>
      { ((dehaId === DeviceHardwareEnum.WATCH_TWO && fiwaNum > 1) || dehaId !== DeviceHardwareEnum.WATCH_TWO ) && <React.Fragment>
        <ValueSelector
          title={{ id: "screenLightMode", defaultMessage: "Screen mode" }}
          name="screenLightMode"
          startLevel={1}
          count={3}
          label={[
            {},
            { id: "screenLightModeSosButton", defaultMessage: "Sos button" },
            { id: "screenLightModeAlwaysOn", defaultMessage: "Always on" },
            { id: "screenLightModeOnRaise", defaultMessage: "On hand raise" },
          ]}
        />
      </React.Fragment>}
    </React.Fragment>
  )
}))

interface SoundFilesLanguageProps extends WrappedComponentProps{
  languages: Language[];
  fiwaNum: number;
  capabilities :Capabilities
}

const mapSoundFilesLanguageStateToProps = ({ appSettings: { languages },settings: {device:{device: {fiwaNum,}, capabilities}}}: AppState) => {
  return {
    languages,
    fiwaNum,
    capabilities
  }
}

export const SoundFilesLanguage = connect(mapSoundFilesLanguageStateToProps)(injectIntl(
  withFormWrapper<SoundLightSettings, SoundFilesLanguageProps>(({formik: { values: { soundFilesLanguage }, setFieldValue }, intl: {formatMessage},  fiwaNum, capabilities, languages}) => {
    const language = React.useMemo(() => {
      return languages.map((language: Language) => {
        return{
          value: `${language.languageCode}`,
          text: formatMessage({id: `${language.languageCode}`, defaultMessage: `${language.languageName}`})
        }
      })
    }, [languages])
    if( capabilities && capabilities.sotaFwnum && capabilities.sotaFwnum !== 0 && fiwaNum >= capabilities.sotaFwnum ){
      return(
        <Row style={{marginTop: "2em"}}>
          <Col md={12}>
            <Label>{formatMessage({id: "soundFilesLanguageLabel", defaultMessage: "Sound files language"})}</Label>
          </Col>
          <Col xs={12} sm={6} md={6}>
            <Dropdown
              options={language}
              onChange={(option) => {
                const { value } = option as Option;
                setFieldValue("soundFilesLanguage", value);
              }}
              initial={soundFilesLanguage}
            />
          </Col>
        </Row>
      )
    } else {
      return null;
    }

  })))

export const WatchFiveTheme = CheckWrap<SoundLightSettings>(SettingEnum.WATCH_FIVE_THEME, {label: {id: "digitalWatchTheme", defaultMessage: "Digital klokke"}});
