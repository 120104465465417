import {call, put, takeLatest, race, take} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction, IRace } from '../../../Store/types';
import * as Routines from "../routines";

function* initialize(action: AppAction<null>){
  try {
    yield put(Routines.initializeAlarms());
    const {success: alarmSuccess, failure: alarmFailure}: IRace<any> = yield race({
      success: take(Routines.initializeAlarms.SUCCESS),
      failure: take(Routines.initializeAlarms.FAILURE)
    });

    yield put(Routines.initializeGrafanaPanels());
    const {success: panelSuccess, failure: panelFailure}: IRace<any> = yield race({
      success: take(Routines.initializeGrafanaPanels.SUCCESS),
      failure: take(Routines.initializeGrafanaPanels.FAILURE)
    });

    yield put(Routines.initializeReport());
    const {success: reportSuccess, failure: reportFailure}: IRace<any> = yield race({
      success: take(Routines.initializeReport.SUCCESS),
      failure: take(Routines.initializeReport.FAILURE)
    });



    if(alarmSuccess && reportSuccess && panelSuccess){
      yield put(Routines.initialize.success());
    }
    else{
      yield put(Routines.initialize.failure());
    }
  }
  catch(e){
    yield put(Routines.initialize.failure(e));
  }
}

function* initializeAlarms(action: AppAction<null>){
  try{
      const alarms = yield call(Api.getAlarms);
      yield put(Routines.initializeAlarms.success(alarms));
  }
  catch(e){
      yield put(Routines.initializeAlarms.failure(e));
  }
}

function* initializeReport(action: AppAction<null>){
  try{
      const report = yield call(Api.getReport);
      yield put(Routines.initializeReport.success(report));
  }
  catch(e){
      yield put(Routines.initializeReport.failure(e));
  }
}

function* initializeGrafanaPanels(action: AppAction<null>){
  try{
    const grafanaPanels = yield call(Api.getGrafanaPanels);
    yield put(Routines.initializeGrafanaPanels.success(grafanaPanels));
  }
  catch(e){
    yield put(Routines.initializeGrafanaPanels.failure(e));
  }
}

function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
    yield takeLatest(Routines.initializeAlarms.TRIGGER, initializeAlarms);
    yield takeLatest(Routines.initializeReport.TRIGGER, initializeReport);
    yield takeLatest(Routines.initializeGrafanaPanels.TRIGGER, initializeGrafanaPanels);
}

export default initializationSaga;
