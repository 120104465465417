import * as React from "react";
import {Form, Formik, } from "formik";
import {connect} from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {saveCustomer } from "../Store/routines";
import {Footer} from "./footer";

import UnsavedCheck from "../../SaveNeeded/unsavedModal";
import { ShippingAddress, Email, AlarmCentral, SubOrgName, OrgNummer, Billing} from "./fields";
import { ParentFirm, MovingSuborg } from "./parentFirm";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import confirmModal from "../../Modals/confirmModal";
import { AppState } from "@Safemate/Store/types";
import { IFullCustomerWithPolicy } from "@Safemate/Model/Customer";
import { CustomerForm } from "./types";
import { mapCustomerToForm } from "./utils";


const NoPadRow = styled(Row)`
  margin: 0px;
`;

interface FormProps{
  selectedCustomer: IFullCustomerWithPolicy;
  saveCustomer: Routine<ActionFunctionAny<Action<any>>>;
  createForm: boolean;
}

const FormComp = ({ selectedCustomer, saveCustomer, createForm }: FormProps) => {
  const {Comp, func} = confirmModal();

  const { formatMessage } = useIntl();
  const saveMessage = formatMessage({id: "subUnitSaved", defaultMessage: "Enhet lagret: "});
  const errorParentMessage = formatMessage({id: "errorMoveSuborg", defaultMessage: "Suborg paid by parent org cannot be moved."} );

  const { Comp: NameValidationModal, func: showNameValidationModal } = confirmModal();

  const validate = (values: CustomerForm) => {
    if(values.customer.parent != selectedCustomer.firm.parent && values.pptyId === 3 && values.customer.custId != 0){
      func(true);
    }else{
      validateName(values)
    }
  }

  const validateName = (values: CustomerForm) => {
    (async () => {
      try{
        const response = await fetch(`/ajax/firm/validateOrgName/${values.customer.lastName}`);
        const result: boolean = await response.json();
        console.log(result);
        if(result){
          showNameValidationModal(true);
        }
        else{
          saveCustomer({formValues: values, selectedCustomer, errorParentMessage, saveMessage});
        }
      }
      catch(e){
        console.log(e);
      }
    })()
  }

  const customerForm = React.useMemo(() => {
    return {
      pptyId: selectedCustomer.paymentProfile.pptyId,
      customer: mapCustomerToForm(selectedCustomer.firm),
      tempAhp: 0
    }
  }, [selectedCustomer])

  return (
    <Formik
      initialValues={customerForm}
      enableReinitialize
      onSubmit={(values) => {
        validate(values);
      }}
    >
      {({values}) => {
        return(
          <Form id="form">
            <h3>{formatMessage({id: createForm ? "newCustomer" : "editCustomer", defaultMessage: createForm ? "Legger til underavdeling" : "Endrer underavdeling"})} {selectedCustomer.firm.lastName}</h3>
            {createForm && <ParentFirm/>}
            {!createForm && selectedCustomer.firm.parent > 0 && <MovingSuborg/>}
            <SubOrgName/>
            <Email custId={selectedCustomer.customerId}/>
            <OrgNummer custId={selectedCustomer.customerId}/>
            <AlarmCentral/>
            <Billing/>
            <NoPadRow>
              <ShippingAddress custId={selectedCustomer.customerId}/>
            </NoPadRow>
            <Footer/>
            <UnsavedCheck/>
            <Comp
              title={`${formatMessage({id: "moveSuborg", defaultMessage: "Move SubOrg"})}`}
              body={
                <p>
                  <span>{formatMessage({id: "aboutAddSuborg", defaultMessage: "You are about to add suborg"})} {selectedCustomer.firm.lastName}</span>
                  
                  <br/>
                  <br/>
                  <span>{formatMessage({id: "wantToContinue", defaultMessage: "Do you want to continue?"} )}</span>
                </p>
              }
              confirmText={formatMessage({id: "ok", defaultMessage: "OK"})}
              confirmFunc={() => {
                validateName(values)
                func(false);
              }}
            />
            <NameValidationModal
              title=""
              standardBtn
              confirmText={formatMessage({id: "yes", defaultMessage: "Yes"})}
              body={
                <React.Fragment>
                <span>{`${values.customer.lastName} ${formatMessage({id: "duplicateCompanyName", defaultMessage: "already exists and is in active use. Are you sure you want to create another instance of"})} ${values.customer.lastName}?`}</span>
                <br/>
                <span><a target="_blank" href="https://servicedesk.sensio.no/nb-NO/support/home">{formatMessage({id: "toSupport", defaultMessage: "To support"})}</a></span>
                </React.Fragment>
              }
              confirmFunc={() => {
                showNameValidationModal(false)
                saveCustomer({formValues: values, selectedCustomer, errorParentMessage, saveMessage});
              }}
            />
          </Form>
        )
      }}

    </Formik>
  )
}

const mapStateToProps = ({ subUnits: {customer: {selectedCustomer, createForm}}}: AppState) => {
  return{
    selectedCustomer,
    createForm,
  }
};

const mapDispatchToProps = {
  saveCustomer
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComp);
