import * as React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import Chart from "react-apexcharts";
import { DeviceHardwareEnum } from "@Safemate/DeviceHardwareEnum";
import { DeviceComponents } from "@Safemate/Settings/tabDefinition";
import { SettingEnum as ComponentSettingEnum } from "../../settingEnum";
import { AppState } from "@Safemate/Store/types";
import { getStepData } from "@Safemate/Settings/Store/routines";
import { DevicePedometer } from "@Safemate/Model/DevicePedometer";
import moment from "moment";
import styled from "styled-components";

const ChartWrapper = styled.div`
  .apexcharts-tooltip {
    background: ${props => props.theme.colors.backgroundPrimary} !important;
    color: ${props => props.theme.colors.textPrimary};
  }
  .apexcharts-tooltip-title{
    border-bottom: none !important;
    background: ${props => props.theme.colors.backgroundPrimary} !important;
    color: ${props => props.theme.colors.textPrimary};
  }
  text{
    fill: ${props => props.theme.colors.textPrimary};
  }
`

const mapStateToProps = ({ settings: {initialization: { dehaId, deviId }}}: AppState) => {
  return{
    dehaId,
    deviId
  }
}

const mapDispatchToProps = {
  getStepData
}

interface StepDataProps{
  dehaId: number;
  deviId: number;
  getStepData: Routine<ActionFunctionAny<Action<any>>>;
}

const StepData = connect(mapStateToProps, mapDispatchToProps)(({ dehaId, deviId, getStepData }: StepDataProps) => {
 
  const { formatMessage } = useIntl();

  const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
    ? DeviceComponents.get(dehaId)! 
    : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;

  React.useEffect(() => {
    getStepData(deviId);
  }, [])
  
  return(
    <div>
      <h2>{formatMessage({id: "stepData", defaultMessage: "Skritteller"})}</h2>
      <div>
          {components.has(ComponentSettingEnum.DEVICE_PEDOMETER) 
            && components.get(ComponentSettingEnum.DEVICE_PEDOMETER)?.map(comp => 
                comp)}
        </div>
    </div>
  )
})

export default StepData;

const mapStateToStepInformationProps = ({ settings: { general: { stepData }}}: AppState) => {
  return {
    stepData
  }
}

interface StepInformationProps{
  stepData: DevicePedometer[]
}

interface MappedDay{
  date: string;
  steps: number;
}

export const StepInformation = connect(mapStateToStepInformationProps)(({ stepData }: StepInformationProps) => {

  const [ selectedDate, setSelectedDate ] = React.useState();
  const [ range, setRange ] = React.useState(14);
  const { formatMessage } = useIntl();

  const mappedDaily = React.useMemo(() => {

    const today = moment();
    const days: {[index: string]: number} = {};

    for(let i = 0; i < range; i++){
      const currentDay = today.subtract(i, "days");
      days[currentDay.format("DD.MM.YYYY")] = 0;
    }

    stepData.forEach(step => {

      const date = moment(step.date);
      const currentDate = date.format("DD.MM.YYYY");

      let stepsDay = days[currentDate] || 0;
      stepsDay += step.steps;
      days[currentDate] = stepsDay;

    })

    const mapped: MappedDay[] = [];

    Object.keys(days).forEach(day => {
      mapped.push({
        date: day,
        steps: days[day]
      })
    })

    return mapped;

  }, [stepData, range])

  if(mappedDaily.length === 0 || stepData.length === 0) return <div>{formatMessage({id: "noData", defaultMessage: "Ingen data"})}</div>;


  const options = {
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: mappedDaily.map(day => day.date).reverse()
    }
  }
  const series = [
    {
      name: formatMessage({id: "steps", defaultMessage: "Skritt"}),
      data: mappedDaily.map(day => day.steps).reverse()
    }
  ]


  return (
    <div className="app">
      <div className="row">
        <ChartWrapper className="mixed-chart">
          <Chart
            options={options}
            series={series}
            type="bar"
          />
        </ChartWrapper>
      </div>
    </div>
  )
})