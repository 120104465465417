import {call, put, takeLatest, all, select} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '@Safemate/Store/types';
import * as Routines from "../routines";
import { IUser } from '@Safemate/Model/User';
import { ISso } from '@Safemate/Model/Sso';
import { CustomerWithChildren, ICustomer, ICustomerWithPolicy } from '@Safemate/Model/Customer';
import { ITexts } from '@Safemate/Model/Text';
import { CustomRole } from '@Safemate/Model/Role';
import {initializeLanguages, initializeMarket, initializeMarketSetting, initializeSettings} from './settings';
import { saveUser } from '@Safemate/PrivateMyBilling/Store/routines';
import { selectCurrentContext, selectIsPrivate } from '../selectors';
import { AhpRoles } from '@Safemate/Policy/GlobalRoleName';
import { SiteTypeEnum } from '@Safemate/SiteTypeEnum';
import AppActionTypes from '../actionTypes';
import { setSiteContext } from '../actions';
import { deleteCustomer, saveCustomer } from '@Safemate/SubUnits/Store/routines';

interface UserType{
  ahp: boolean;
  pro: boolean;
}

function* initialize(action: AppAction<null>){
  try {

    const currentContext: SiteTypeEnum = yield select(selectCurrentContext);
    const userType: UserType = yield call(Api.getUserType);
    yield put(Routines.isAhp.success(userType.ahp));
    yield put(Routines.isPro.success(userType.pro));

    if(currentContext === SiteTypeEnum.PROFFWEB){
      if(!userType.pro && userType.ahp){
        yield put(setSiteContext(SiteTypeEnum.AHP));
      }
      else{
        try{
          yield call(Api.authPro);
          yield all([
            call(initializeActiveRoles, action),
          ])
        }
        catch(e){
          yield put(Routines.authPro.failure(e));
        }
      }
    }
    else if(currentContext === SiteTypeEnum.AHP){
      if(!userType.ahp && userType.pro){
        yield put(setSiteContext(SiteTypeEnum.PROFFWEB));
      }
      else{
        try{
          yield call(Api.authAhp);
          yield all([
            call(initializeAhpRoles, action),
          ])
        }
        catch(e){
          yield put(Routines.authAhp.failure(e));
        }
      }
    }

    if(userType.pro){
      yield all([
        call(initializeSuborgs, action),
        call(initializeCustomerHierarchy, action),
        call(initializeGdprManager, action),
        call(initializeRequireNationalId, action),
        call(initializePreventPartnerReferenceChange, action)
      ]);
    }

    yield all([
      call(initializeUser, action),
      call(initializeSso, action),
      call(initializeIsSso, action),
      call(initializeAdminSso, action),
      call(initializeCustomer, action),
      call(initializeTexts, action),
      call(initializePrivate, action),
      call(initializePartner, action),
      call(initializeMunicipalities, action),
      call(initializePartnerUser, action),
      call(initializeBackdoorUser, action),
      call(initializeRestrictedUser, action),
      call(initializeSettings, action),
      call(initializeMarket, action),
      call(initializeMarketSetting, action),
      call(initializeLanguages, action)
    ]);
    yield put(Routines.initialize.success());
  }
  catch(e){
    yield put(Routines.initialize.failure());
  }
  yield put(Routines.initialize.fulfill());
}

function* initializeUser(action: AppAction<null>){
  try{
      const user: IUser = yield call(Api.getUser);
      yield put(Routines.getUser.success(user));
  }
  catch(e){
      yield put(Routines.getUser.failure(e));
  }
  yield put(Routines.getUser.fulfill());
}

function* initializeSso(action: AppAction<null>){
  try{
      const sso: ISso = yield call(Api.getSso);
      yield put(Routines.getSso.success(sso));
  }
  catch(e){
      yield put(Routines.getSso.failure(e));
  }
}

function* initializeIsSso(action: AppAction<null>){
  try{
      const isSso: boolean = yield call(Api.isSso);
      yield put(Routines.isSso.success(isSso));
  }
  catch(e){
      yield put(Routines.isSso.failure(e));
  }
}

function* initializeAdminSso(action: AppAction<null>){
  try{
      const adminSso: boolean = yield call(Api.getAdminSso);
      yield put(Routines.getAdminSso.success(adminSso));
  }
  catch(e){
      yield put(Routines.getAdminSso.failure(e));
  }
}

function* initializeCustomer(action: AppAction<null>){
  try{
    const customer: ICustomer = yield call(Api.getCustomer);
    yield put(Routines.getCustomer.success(customer));
  }
  catch(e){
    yield put(Routines.getCustomer.failure(e));
  }
}

function* initializeTexts(action: AppAction<null>){
  try{
    const texts: ITexts = yield call(Api.getTexts);
    yield put(Routines.getTexts.success(texts));
  }
  catch(e){
    yield put(Routines.getTexts.failure(e));
  }
}

function* initializePrivate(action: AppAction<null>){
  try{
    const isPrivate: boolean = yield call(Api.getPrivate);
    if(isPrivate){
      yield call(initializeBilling, action);
    }
    yield put(Routines.getPrivate.success(isPrivate));
  }
  catch(e){
    yield put(Routines.getPrivate.failure(e));
  }
}

function* initializeBilling(action: AppAction<null>){
  try{
    const isBillingFilled: boolean = yield call(Api.getBillingFilled);
    yield put(Routines.getBillingFilled.success(isBillingFilled));
  }
  catch(e){
    yield put(Routines.getBillingFilled.failure(e));
  }
}

function* initializePartner(action: AppAction<null>){
  try{
    const isPartner: boolean = yield call(Api.getPartner);
    yield put(Routines.getPartner.success(isPartner));
  }
  catch(e){
    yield put(Routines.getPartner.failure(e));
  }
}

function* initializeAhp(action: AppAction<null>){
  try{
    const isAhp: boolean = yield call(Api.isAhp);
    yield put(Routines.isAhp.success(isAhp));
  }
  catch(e){
    yield put(Routines.isAhp.failure(e));
  }
}

function* initializePro(action: AppAction<null>){
  try{
    const isPro: boolean = yield call(Api.isPro);
    yield put(Routines.isPro.success(isPro));
  }
  catch(e){
    yield put(Routines.isPro.failure(e));
  }
}

function* initializeSuborgs(action: AppAction<null>){
  try{
    const suborgs: ICustomerWithPolicy[] = yield call(Api.getSuborgsWithRoles);
    yield put(Routines.getSuborgsWithRoles.success(suborgs));
  }
  catch(e){
    yield put(Routines.getSuborgsWithRoles.failure(e));
  }
}

function* initializeMunicipalities(action: AppAction<null>){
  try{
    const municipalities: ICustomerWithPolicy[] = yield call(Api.getMunicipalities);
    yield put(Routines.getMunicipalities.success(municipalities));
  }
  catch(e){
    yield put(Routines.getMunicipalities.failure(e));
  }
}

function* initializePartnerUser(action: AppAction<null>){
  try{
    const partnerUser: string = yield call(Api.getPartnerUser);
    yield put(Routines.getPartnerUser.success(partnerUser));
  }
  catch(e){
    yield put(Routines.getPartnerUser.failure(e));
  }
}

function* initializeBackdoorUser(action: AppAction<null>){
  try{
    const backdoorUser: string = yield call(Api.getBackdoorUser);
    yield put(Routines.getBackdoorUser.success(backdoorUser));
  }
  catch(e){
    yield put(Routines.getBackdoorUser.failure(e));
  }
}

function* initializeRestrictedUser(action: AppAction<null>){
  try{
    const restrictedUser: boolean = yield call(Api.getRestrictedUser);
    yield put(Routines.getRestrictedUser.success(restrictedUser));
  }
  catch(e){
    yield put(Routines.getRestrictedUser.failure(e));
  }
}

function* redirectPartner(action: AppAction<null>){
  try{
    const partnerUrl: string = yield call(Api.redirectPartner);
    window.location.href = partnerUrl;
    yield put(Routines.redirectPartner.success(partnerUrl));
  }
  catch(e){
    yield put(Routines.redirectPartner.failure(e));
  }
}

function* initializeActiveRoles(action: AppAction<null>){
  try{
    const roles: CustomRole[] = yield call(Api.getActiveRoles);
    yield put(Routines.getActiveRoles.success(roles));
  }
  catch(e){
    yield put(Routines.getActiveRoles.failure(e));
  }
}

function* initializeAhpRoles(action: AppAction<null>){
  try{
    const roles: AhpRoles = yield call(Api.getAhpRoles);
    yield put(Routines.getAhpRoles.success(roles));
  }
  catch(e){
    yield put(Routines.getAhpRoles.failure(e));
  }
}

function* initializeGdprManager(action: AppAction<null>){         
  try{
    const gdprManager: number = yield call(Api.getGdprManager);
    yield put(Routines.getGdprManager.success(gdprManager));
  }
  catch(e){
    yield put(Routines.getGdprManager.failure(e));
  }
}

function* initializeRequireNationalId(action: AppAction<null>){
  try{
    const requireNationalId: boolean = yield call(Api.getRequireNationalId);
    yield put(Routines.getRequireNationalId.success(requireNationalId));
  }
  catch(e){
    yield put(Routines.getRequireNationalId.failure(e));
  }
}

function* initializePreventPartnerReferenceChange(action: AppAction<null>){
  try{
    const preventPartnerReferenceChange: boolean = yield call(Api.getPreventPartnerReferenceChange);
    yield put(Routines.getPreventPartnerReferenceChange.success(preventPartnerReferenceChange));
  }
  catch(e){
    yield put(Routines.getPreventPartnerReferenceChange.failure(e));
  }
}

function* getLastAlarm(action: AppAction<null>){
  try{

    const isPrivate: boolean = yield select(selectIsPrivate);
    const lastAlarm: number = yield call(isPrivate ? Api.getLastAlarmPrivate : Api.getLastAlarm);

    yield put(Routines.getLastAlarm.success(lastAlarm));
  }
  catch(e){
    yield put(Routines.getLastAlarm.failure(e));
  }
}


function* initializeCustomerHierarchy(action: AppAction<string>){
  try{
    const customerHierarchy: CustomerWithChildren = yield call(Api.getHierarchy)
    yield put(Routines.initializeCustomerHierarchy.success(customerHierarchy));
  }
  catch(e){
    yield put(Routines.initializeCustomerHierarchy.failure());
  }
}

function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
    yield takeLatest(AppActionTypes.SET_SITE_CONTEXT, initialize);
    yield takeLatest(Routines.getTexts.TRIGGER, initializeTexts);
    yield takeLatest(saveUser.SUCCESS, initializeTexts);
    yield takeLatest(Routines.redirectPartner.TRIGGER, redirectPartner);
    yield takeLatest(Routines.getUser.TRIGGER, initializeUser);
    yield takeLatest(Routines.getSso.TRIGGER, initializeSso);
    yield takeLatest(Routines.getLastAlarm.TRIGGER, getLastAlarm);
    yield takeLatest(Routines.getMarket.TRIGGER, initializeMarket);
    yield takeLatest(Routines.getSuborgsWithRoles.TRIGGER, initializeSuborgs);
    yield takeLatest(Routines.initializeCustomerHierarchy.TRIGGER, initializeCustomerHierarchy);
    yield takeLatest(saveCustomer.SUCCESS, initializeSuborgs);
    yield takeLatest(deleteCustomer.SUCCESS, initializeSuborgs);
}

export default initializationSaga;
